import { Widgets } from "@rjsf/material-ui";
import { TextWidgetProps } from "@rjsf/material-ui/dist/TextWidget";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

const { TextWidget } = Widgets;

const SchemaInput = (props: TextWidgetProps) => {
  const InputProps = props.readonly
    ? {}
    : {
        endAdornment: (
          <InputAdornment position="start">
            <EditIcon />
          </InputAdornment>
        ),
      };

  return <TextWidget {...props} InputProps={InputProps} />;
};

export default SchemaInput;
