import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export type downloadPDFItemProps = { label: string; href: any };

const DownloadPDFItem = ({ label, href }: downloadPDFItemProps) => {
  return (
    <MenuItem>
      <a href={href} download>
        {label}
      </a>
    </MenuItem>
  );
};

export default DownloadPDFItem;
