import axios from 'axios';
import store from "../../app/store";
import {fetchAuthedUser} from "../../features/auth/authSlice";


//let BaseURL='https://v3dobwa2gc.execute-api.ap-southeast-2.amazonaws.com/prod/portal'

const fetchClient = () => {


    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use(async function (config) {
        await store.dispatch(fetchAuthedUser());
        const idToken = store.getState().auth.user.signInUserSession.idToken;
        config.headers.Authorization = idToken ? `Bearer ${idToken.jwtToken}` : '';
        return config;
    });

    return instance;
};


//export default baseUrl;

export default fetchClient();