import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, signOut } from "../../features/auth/authSlice";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AuthMenuItem from "../AuthMenuItem";
import { Link } from "react-router-dom";
import Mmenu from "../Menu";
import { Menu } from "@material-ui/core";
import { dashboardSelector } from "../../features/dashboard/dashboardSlice";
import logo from "../../media/images/logo.png";
import { registerAsyncReducers } from "./registerAsyncReducers";
import GlobalSearch from "./GlobalSearch";
import HelpComponent from "./HelpComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-root": {
      display: "none",
      width: "100%",
      maxWidth: 500,
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    "& .MuiInputBase-root": {
      background: "white",
    },
  },

  mobileMenu: {
    "& .MuiPopover-paper": {
      minWidth: 200,
    },
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

const PrimaryAppBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { authState } = useSelector(authSelector);
  const { menu, loading } = useSelector(dashboardSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu) {
      registerAsyncReducers(menu);
    }
  }, [menu]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = async () => {
    await dispatch(signOut());
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const MenuItems = () => (
    <Fragment>
      <AuthMenuItem authState={authState}>
        <MenuItem component={Link} to={"/account"}>
          <AccountCircle />
        </MenuItem>
        <HelpComponent />
        {loading ? (
          <MenuItem>Menu is loading...</MenuItem>
        ) : menu ? (
          <Mmenu options={Object.values(menu)} />
        ) : null}
        <MenuItem data-testid="signoutMenuItem" onClick={handleLogout}>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </AuthMenuItem>
    </Fragment>
  );

  const Mobilemenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mobileMenu}
    >
      <AuthMenuItem authState={authState}>
        <MenuItem>
          <GlobalSearch />
        </MenuItem>
      </AuthMenuItem>
      <MenuItems />
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <MenuItem component={Link} to={"/"}>
            <img src={logo} alt="Logo" />
          </MenuItem>

          <div className={classes.grow}>
            <AuthMenuItem authState={authState}>
              <GlobalSearch />
            </AuthMenuItem>
          </div>
          <div className={classes.sectionDesktop}>
            <MenuItems />
          </div>
          <div className={classes.sectionMobile}>
            <AuthMenuItem authState={authState}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </AuthMenuItem>
          </div>
        </Toolbar>
      </AppBar>
      <Mobilemenu />
    </div>
  );
};

export default PrimaryAppBar;

const menuItemExample = [
  { type: "view", actionObject: { label: "Quote List", route: "/quotes" } },
];
const menu = {
  name: "Mark Smith",
  role: "Developer",
  organisation: "Field Solutions Group",
  modules: {
    users: {
      label: "Accounts",
      entity_name: "company_user",
      acl: ["Read"],
    },
    orders: {
      label: "Orders",
      entity_name: "order",
      acl: ["Read", "Create"],
    },
    "ee.quotes": {
      label: "EE Quotes",
      entity_name: "ee.quote",
      acl: ["Read", "Create"],
    },
  },
  menu: {
    quotes: {
      label: "Quotes",
      children: [
        {
          label: "List Quotes",
          action_type: "list",
          action_entity: "eequote",
        },
        {
          label: "NEW EE Quote",
          action_type: "new",
          action_entity: "eequote",
        },

        {
          label: "example",
          children: [
            {
              label: "List Quotes",
              action_type: "list",
              action_entity: "eequote",
            },
            {
              label: "NEW EE Quote",
              action_type: "new",
              action_entity: "eequote",
            },
          ],
        },
      ],
    },

    Orders: {
      label: "Orders",
      children: [
        {
          label: "ORDERS",
          action_type: "list",
          action_entity: "order",
        },
        {
          label: "NEW ORDER",
          action_type: "new",
          action_entity: "order",
        },
      ],
    },
    Cases: {
      label: "Cases",
      children: [
        {
          label: "Cases",
          action_type: "list",
          action_entity: "case",
        },
        {
          label: "New Case",
          action_type: "new",
          action_entity: "case",
        },
      ],
    },
    example: {
      label: "example",
      action_type: "view",
      action_entity: "example",
    },
  },
};
