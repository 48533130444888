import withStyles from "@material-ui/core/styles/withStyles";

import { Button } from "@material-ui/core";

export const RoundButton = withStyles((theme) => ({
  root: {
    borderRadius: 20,
  },
  label: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}))(Button);

export const YellowButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.main,
  },
}))(RoundButton);

export const RedButton = withStyles((theme) => ({
  root: {
    width: "auto",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}))(Button);
