import React, { Fragment } from "react";
import ActionItem, { ActionItemType } from "./ActionComponent/ActionItem";
import ActionDropDown from "./ActionComponent/ActionDropdown";
import { LinkObject } from "./ActionComponent/LinkActionItem";

export type RowOption =
  | {
      label: string;
      children: Array<RowOption>;
    }
  | { label: string; action_type: ActionItemType; action_entity: string };
type Option = { type: ActionItemType; actionObject: LinkObject };
type OptionWithChildren = { label: string; children: Option[] };

//https://stackoverflow.com/questions/54017454/jsx-element-type-element-is-not-a-constructor-function-for-jsx-elements

const Mmenu = ({ options }: { options: RowOption[] }) => {
  // @ts-ignore
  const menuOptions = (options_: RowOption[]) => {
    return options_.map((option) =>
      "children" in option
        ? {
            label: option.label,
            children: menuOptions(option.children),
          }
        : {
            type: option["action_type"],
            actionObject: {
              label: option.label,
              route: `/${option["action_type"]}/${option["action_entity"]}`,
            },
          }
    );
  };

  return (
    <Fragment>
      {menuOptions(
        options
      ).map((option: Option | OptionWithChildren, index: number) =>
        "children" in option ? (
          <ActionDropDown
            options={option.children}
            name={option.label}
            key={index}
          />
        ) : (
          <ActionItem
            type={option.type}
            actionObject={option.actionObject}
            key={index}
          />
        )
      )}
    </Fragment>
  );
};
export default Mmenu;
