import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {DropzoneAreaBase, DropzoneAreaBaseProps} from "material-ui-dropzone";
import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";


const theme = createMuiTheme({
    overrides: {
        // @ts-ignore
        MuiDropzoneArea: {
            root: {
                minHeight: 20
            },
            text: {
                marginBottom: 4,
                fontSize: '1rem'
            },
            icon: {
                height: 30,
                width: 30,
                marginBottom: 12
            }
        },
        MuiFormLabel: {
            root: {
                color: '#546e7a'
            },

        }
    },

});

type ownType = {

    label: string;
    disabled?: boolean
}

const DropZone = (props: DropzoneAreaBaseProps & ownType) => {

    const {label, ...dropzoneAreaBaseProps} = props
    const disabled=dropzoneAreaBaseProps.dropzoneProps?.disabled||false

    return <MuiThemeProvider theme={theme}>
        <Box mb={1}>
            <FormLabel htmlFor={label} disabled={disabled}>
                {label}
            </FormLabel>
        </Box>
        <DropzoneAreaBase {...dropzoneAreaBaseProps}/>
    </MuiThemeProvider>
}
export default DropZone




