import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, signOut} from "../../features/auth/authSlice";
import {useHistory} from "react-router";
import {Alert} from "@material-ui/lab";


const PasswordResetCodeValidation: React.FC = () => {
    const history = useHistory();
    const {passwordReset} = useSelector(authSelector)
    const dispatch = useDispatch();
    const [showMessage, setShowMessage]=useState(false)

    const logout = () => {
        dispatch(signOut());
        history.push("/login")

    };

    useEffect(() => {
        if (passwordReset === 'success') {
            setTimeout(logout, 2000)
            setShowMessage(true)
        }
    }, [passwordReset])

    return (
        <Box flexGrow={1} textAlign="center">
            {showMessage?<Alert severity="success" variant='filled'>Your Password is successful reset! Redirecting to login...</Alert>:null}
            <ForgotPasswordSubmit/>
        </Box>
    );
};

export default PasswordResetCodeValidation



