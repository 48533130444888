import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import ForgotPassword from "./ForgotPassword";

import {useSelector} from "react-redux";
import {authSelector} from "../../features/auth/authSlice";
import {useHistory} from "react-router";


const PasswordReset: React.FC = () => {

    let history = useHistory();
    const {passwordReset} = useSelector(authSelector)

    useEffect(() => {

        if (passwordReset === 'verifyCode') {
            history.push('/password-reset-code-validation')
        }

    }, [passwordReset])


    return (
        <Box flexGrow={1} textAlign="center">
            <ForgotPassword/>
        </Box>
    );
};

export default PasswordReset



