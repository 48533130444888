import React from "react";
import { useHistory, useParams } from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container } from "@material-ui/core";
import MWidgets, { WidgetsMap } from "../MWidget";
import { actionButtonMap } from "../actionButtonMap";
import { reloadForm } from "../../features/form/formSlice";
import { useDispatch } from "react-redux";
import MessageList from "../MessageList";
import AccessDeniedView from "../AccessDeniedView";
import BaseForm from "./BaseForm";
import useSchemaForm from "../../Hooks/useSchemaForm";
import LoadingComponent from "../LoadingComponent";
import {
  ActionButtonProps,
  BaseButtons,
  GetActionButtonProps,
  TopButtons,
} from "./ButtonsGroups";
import { isEmpty } from "../../common/util/util";

export type State = "view" | "edit" | "new";
type styleProps = {
  state: State;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    paddingTop: 50,
    position: "relative",
    "& .MuiInputLabel-formControl": {
      transform: "translate(0, 1.5px) scale(0.85)",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },

    "& .MuiInputAdornment-root": {
      display: (props: styleProps) => props.state !== "edit" && "none",
    },

    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottom: "none",
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: theme.palette.text.primary,
    },

    "& .MuiFormLabel-root": {
      // color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
    },
    "& label.Mui-disabled ": {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },

    "& .Mui-disabled.MuiInputLabel-shrink": {
      transform: "translate(0, 1.5px) scale(1)",
    },
    "& .MuiAlert-standardWarning .MuiAlert-icon .MuiFormControlLabel-label": {
      color: theme.palette.text.primary,
    },
  },

  submit: {
    display: (props: styleProps) => (props.state === "view" ? "none" : "block"),
    width: "48%",
  },
}));

const getApiUrl = (state: string, entityType: string, id: string) => {
  return {
    schemaUrl: `/actionroute/${entityType}/${state}/${id}`,
    submitUrl: `/actionroute/${entityType}/submit/${id}`,
  };
};

const FormPage = () => {
  const { entityType, state, id } = useParams();
  const { root } = useStyles({ state });
  const _id = id ? id : "";
  const history = useHistory();
  const dispatch = useDispatch();

  const { schemaUrl, submitUrl } = getApiUrl(state, entityType, _id);

  const goNextStep = (action: string, _entityType: string, _id: string) => {
    if (action === state && _entityType === entityType && _id === id) {
      dispatch(reloadForm(true));
    }
    history.push(`/form/${action}/${_entityType}/${_id}`);
  };

  const goList = () => {
    setTimeout(() => history.replace(`/list/${entityType}`), 1000);
  };

  const callback = (data: any) => {
    const { nextStep } = data.data;
    if (nextStep) {
      const { action, entityType, id } = nextStep;
      goNextStep(action, entityType, id);
      setMessageList({ messageList: [], type: undefined });
    } else goList();
  };

  const {
    messageList,
    setMessageList,
    schema,
    uiSchema,
    formData,
    setFormData,
    initialFormData,
    underWidgets,
    overWidgets,
    submitting,
    loading,
    onSubmit,
    transformErrors,
    config,
  } = useSchemaForm(submitUrl, schemaUrl, callback);

  const { messageList: messageList_, type } = messageList;
  const baseButtons = config?.baseActions?.filter(
    (item: ActionButtonProps) => item.display
  );
  const topButtons = config?.topActions?.filter(
    (item: ActionButtonProps) => item.display
  );

  const getActionButton = ({ type, params }: GetActionButtonProps) => {
    switch (type) {
      case "cancel":
        const formChanged =
          JSON.stringify(initialFormData.current) !== JSON.stringify(formData);
        return actionButtonMap["cancel"]({
          entityType,
          formChanged,
          onCancel: goList,
        });
      case "submit":
        return actionButtonMap["submit"]({ submitting });
      case "cancelEntity":
        return actionButtonMap["cancelEntity"]({
          title: params?.title || "Cancel",
          entityType,
          id,
          buttonLabel:
            params?.buttonLabel ??
            (entityType === "service" ? "Cancel Service" : "Cancel"),
        });
      case "createCase":
        return actionButtonMap["createCase"]({
          title: params ? params.title : "Create Case",
          serviceId: id,
        });
      default:
        return null;
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (messageList_.length > 0 && messageList_[0] === "Access Denied") {
    return <AccessDeniedView />;
  }

  console.log(formData);

  return (
    <Container maxWidth="lg" className={root}>
      {!isEmpty(messageList_) && (
        <MessageList messageList={messageList_} type={type} />
      )}

      {overWidgets && !isEmpty(overWidgets) && (
        <MWidgets widgets={WidgetsMap} data={overWidgets} />
      )}

      {topButtons && (
        <TopButtons buttons={topButtons} getActionButton={getActionButton} />
      )}

      {formData && (
        <BaseForm
          schema={schema || {}}
          uiSchema={uiSchema}
          formData={formData}
          setFormData={setFormData}
          onSubmit={onSubmit}
          transformErrors={transformErrors}
        >
          {baseButtons && (
            <BaseButtons
              buttons={baseButtons}
              getActionButton={getActionButton}
            />
          )}
        </BaseForm>
      )}

      {underWidgets && !isEmpty(underWidgets) && (
        <MWidgets widgets={WidgetsMap} data={underWidgets} />
      )}
    </Container>
  );
};
export default FormPage;

//https://github.com/rjsf-team/react-jsonschema-form/issues/1602
//https://www.digitalocean.com/community/tutorials/js-file-reader

/*const _overWidgets = [
    {
        type: "stepper",
        values: {
            state: {
                "Search Address": {
                    clickable: true,
                    dataUrl:
                        "actionRoute/eequote/edit/ee016641-c86f-4a81-bfa2-e3b04ea059e5",
                    message:
                        "Any changes added since Search Address will be lost - continue?",
                },
                Qualify: {},
                "Technology Selection": {},
                Configure: {},
                "Quote Complete": {},
                "Convert To Order": {},
            },
            currentState: "Configure",
        },
    },
];*/
