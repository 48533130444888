import React from "react";
import { VictoryLabel, VictoryPie } from "victory";

type GaugeChart = {
  amount: number;
  pieArray: [number, number, number];
  targetAmount: number;
  displayUnit: string;
};

const GaugeChart = ({
  amount,
  pieArray,
  targetAmount,
  displayUnit,
}: GaugeChart) => {
  const formatAmountLabel = (amount: number, displayUnit: string) => {
    if (displayUnit === "currency") {
      return `$${amount}`;
    } else if (displayUnit === "currencyK") {
      return `$${amount}K`;
    } else if (displayUnit === "currencyM") {
      return `$${amount}M`;
    } else {
      return amount;
    }
  };

  const getData = () => {
    let gradations = [...pieArray].sort((a: number, b: number) => a - b);

    let max = gradations[pieArray.length - 1];
    let chunks = [];
    let colors = ["red", "orange", "green", "transparent"];
    let trans = false;
    let hideLabel = 0;
    for (var i = 0; i < gradations.length; i++) {
      let val = gradations[i];
      let prevVal = i > 0 ? gradations[i - 1] : 0;
      let color = colors.shift();
      let data = {
        x: formatAmountLabel(val, displayUnit),
        y: (val - prevVal) / max,
        color: color,
      };
      if (amount < val && amount > prevVal) {
        // shorten
        chunks.push({
          x: false, // don't display label
          y: (amount - prevVal) / max,
          color: `${data.color}`,
        });
        hideLabel = i + 1;
        // remainder in transparent
        data.y = (val - amount - prevVal) / max;
        trans = true;
      }
      if (trans) {
        data.color = "transparent";
      }
      chunks.push(data);
    }

    let colorList = chunks.map((item) => `${item.color}`);

    return {
      data: chunks.map((item) => {
        return {
          x: item.x,
          y: item.y, // Math.floor(180*(item.y/pieArray[2]))
        };
      }),
      color: colorList,
      labelIndex: hideLabel,
    };
  };

  const { data, color, labelIndex } = getData();

  return (
    <svg width={320} height={170}>
      <defs>
        <pattern
          id="pattern-stripe"
          width="4"
          height="4"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(10)"
        >
          <rect
            width="2"
            height="4"
            transform="translate(0,0)"
            fill="#d3d3d3"
          ></rect>
        </pattern>
        <mask id="mask-stripe">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-stripe)"
          />
        </mask>
      </defs>
      <VictoryPie
        colorScale={["white"]}
        standalone={false}
        width={300}
        height={300}
        innerRadius={90}
        startAngle={-90}
        endAngle={90}
        labelPosition={"endAngle"}
        style={{ data: { fill: "url(#pattern-stripe)" } }}
        // @ts-ignore
        data={[
          { x: "0", y: 0 },
          {
            x: `${formatAmountLabel(targetAmount, displayUnit)}`,
            y: 1000,
          },
        ]}
      />

      <VictoryPie
        colorScale={color}
        standalone={false}
        width={300}
        height={300}
        innerRadius={90}
        startAngle={-90}
        endAngle={90}
        labelPosition={"endAngle"}
        labels={({ datum }) => (datum.x ? `${datum.x}` : null)}
        data={data}
      />
      <VictoryLabel
        textAnchor="middle"
        verticalAnchor="middle"
        x={150}
        y={150}
        text={`${formatAmountLabel(amount, displayUnit)}`}
        style={{ fontSize: 30 }}
      />
    </svg>
  );
};

export default GaugeChart;
