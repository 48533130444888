import React, { FormEvent, useEffect } from "react";
import { Box, Container, Link, TextField } from "@material-ui/core";
import FormTitle from "./FormTitle";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, signIn } from "../../features/auth/authSlice";
import { useHistory } from "react-router";
import useFormFields from "../../Hooks/useFormFields";
import SubmitButton from "../../components/Form/SubmitButton";

const SignIn = () => {
  const { formFields, handleChangeValue } = useFormFields({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const { loading, error, authState } = useSelector(authSelector);
  const history = useHistory();
  const { email, password } = formFields;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(signIn(email, password));
  };

  useEffect(() => {
    if (authState === "authed") {
      history.replace("/");
    }
  }, [authState]);

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" mt={8}>
          <FormTitle>Sign In</FormTitle>
          <Box
            display="flex"
            justifyContent="center"
            fontWeight={600}
            color="error.main"
          >
            {error}
          </Box>
          <Box width="100%" my={2}>
            <TextField
              autoComplete="email"
              type="email"
              onChange={handleChangeValue("email")}
              value={email}
              label={loading ? " " : "Email Address"}
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "email" }}
            />
          </Box>
          <Box width="100%" my={2}>
            <TextField
              label={loading ? " " : "Password"}
              type="password"
              autoComplete="current-password"
              onChange={handleChangeValue("password")}
              value={password}
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "password" }}
            />
          </Box>
          <Box width="100%" mt={4} mb={2} className="relative">
            <SubmitButton submitting={loading}>Sign In</SubmitButton>
          </Box>
          <Box width="100%" my={2} display="flex" justifyContent="center">
            <Link
              data-testid="forgotPasswordLink"
              variant="body2"
              href={"/request-password-reset"}
            >
              Forgot password?
            </Link>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default SignIn;
