import { ListActions, ListState } from "../../common/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchData } from "../../service/common";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  VirtualTableProps,
} from "@devexpress/dx-react-grid-material-ui";
import {
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  PagingState,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import LoadingComponent from "../LoadingComponent";
import StatusTypeProvider from "./StatusTypeProvider";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import {
  buildQueryString,
  getActionFormatter,
  getfilterString,
  getRowId,
  getSortQueryString,
} from "./Helper";

type GridListParams = {
  listState: ListState;
  listActions: ListActions;
  helperChildren?: any;
  api: string;
};

const useStyles = makeStyles({
  root: {
    '& [class*="Title-title"]': {
      fontWeight: 600,
    },
    '& [class*="TableContainer-root"]': {
      height: "70vh!important",
    },
    "& table": {
      tableLayout: "auto",
    },
  },
  noDataRowComponent: {
    paddingTop: 50,
  },
});

const columnWidthAdjust = {
  totalPrice: 100,
  speed: 90,
  POI: 90,
  tech: 85,
};

const GridList = ({ listState, listActions, api }: GridListParams) => {
  const classes = useStyles();

  const {
    rows,
    columns,
    currentPage,
    pageSize,
    filters,
    totalCount,
    loading,
    lastQuery,
    sortBy,
    entityType,
    selection,
    bulkAction,
  } = listState;

  const {
    fetchInit,
    loadingError,
    updateFilters,
    updatePage,
    updateQuery,
    updateRow,
    updateSortBy,
    updateSelection,
  } = listActions;

  const { name } = useParams<{ name: string }>();
  const dispatch = useDispatch();
  const [actionsColumn] = useState(["actions"]);
  const [statusColumn] = useState(["_topState"]);
  const getTableColumnExtensions = () =>
    columns.map(({ name }) => {
      return {
        columnName: name,
        wordWrapEnabled: true,
        width: Object.keys(columnWidthAdjust).includes(name)
          ? columnWidthAdjust[name]
          : "auto",
      };
    });

  const [tableColumnExtensions, setTableColumnExtensions] = useState<
    VirtualTableProps["columnExtensions"]
  >([]);

  useEffect(() => {
    dispatch(
      listActions.updateRow({
        entityType: name,
      })
    );
  }, [name]);

  useEffect(() => {
    const tableColumnExtensions = getTableColumnExtensions();
    setTableColumnExtensions(tableColumnExtensions);
  }, [columns]);

  const setCurrentPage = (pageNumber: number) => {
    dispatch(updatePage({ currentPage: pageNumber }));
  };

  const setFilters = (filters: Array<Object>) => {
    dispatch(updateFilters({ filters }));
  };

  const setSortBy = (sortBy: Array<Object>) => {
    dispatch(updateSortBy({ sortBy }));
  };

  const loadData = (queryString: string) => {
    if (queryString !== lastQuery && !loading) {
      dispatch(fetchInit());
      fetchData(queryString)
        .then((data) => {
          console.log(data);

          const {
            columns,
            rows,
            totalCount,
            filterConfig,
            bulkAction,
          } = data.data;

          dispatch(
            updateRow({
              columns,
              rows,
              totalCount,
              filterConfig,
              bulkAction,
              loading: false,
            })
          );
        })
        .catch(() => dispatch(loadingError()));
      dispatch(updateQuery(queryString));
    }
  };

  useEffect(
    () =>
      loadData(
        buildQueryString(
          api,
          currentPage,
          pageSize,
          getfilterString(filters),
          getSortQueryString(sortBy)
        )
      ),
    [entityType, currentPage, pageSize, sortBy, filters]
  );

  const ActionProvider = (props: any) => (
    <DataTypeProvider
      formatterComponent={getActionFormatter(entityType)}
      {...props}
    />
  );

  return (
    <Paper className={classes.root}>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        {bulkAction && (
          <SelectionState
            selection={selection}
            onSelectionChange={(selection) =>
              dispatch(updateSelection({ selection }))
            }
          />
        )}
        {bulkAction && <IntegratedSelection />}
        <SortingState sorting={sortBy} onSortingChange={setSortBy} />
        <FilteringState onFiltersChange={setFilters} />

        <ActionProvider for={actionsColumn} />

        <StatusTypeProvider for={statusColumn} />

        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
        />

        <CustomPaging totalCount={totalCount} />
        <Table
          columnExtensions={tableColumnExtensions}
          noDataRowComponent={() =>
            loading ? (
              <tr>
                <td colSpan={12}>
                  <LoadingComponent />
                </td>
              </tr>
            ) : (
              <tr>
                <td
                  className={classes.noDataRowComponent}
                  align={"center"}
                  colSpan={12}
                >
                  No Data
                </td>
              </tr>
            )
          }
        />

        <TableHeaderRow showSortingControls />
        {bulkAction && <TableSelection showSelectAll />}
        <PagingPanel />
      </Grid>
    </Paper>
  );
};

export default GridList;

//css customization: https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/fundamentals/
