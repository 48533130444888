import React from "react";
import { RowOption } from "../Menu";
import ActionItem, { ActionItemType } from "../ActionComponent/ActionItem";

export const buildQueryString = (
  api: string,
  currentPage: number,
  pageSize: number,
  filterString: string,
  sortString: string
) =>
  `${api}?currentPage=${currentPage}&pageSize=${pageSize}${sortString}${filterString}`;

export const getfilterString = (filters: any[]) => {
  let queryString = "";
  if (filters.length > 0) {
    queryString = `&filter=${JSON.stringify(filters)}`;
  }

  return queryString;
};

export const getSortQueryString = (
  sortBy: { columnName: string; direction: string }[]
) => {
  let queryString = "";
  if (sortBy.length > 0) {
    const sortingConfig = sortBy.map(({ columnName, direction }) => ({
      selector: columnName,
      desc: direction === "desc",
    }));
    const sortingStr = JSON.stringify(sortingConfig);
    queryString = `${queryString}&sort=${escape(`${sortingStr}`)}`;
  }

  return queryString;
};

export const checkCreateNewPermission = (
  menuOptionArray: Array<RowOption>,
  entityName: string
): boolean => {
  const hasPermission: boolean = !!menuOptionArray.find((item) => {
    if ("children" in item) {
      return checkCreateNewPermission(item.children, entityName);
    } else {
      return (
        item["action_entity"] === entityName && item["action_type"] === "new"
      );
    }
  });

  return hasPermission;
};

export const getRowId = (row: { id: string; tid: string }) => row.id || row.tid;

export const getActionFormatter = (entityType: string) => ({
  value,
  row,
}: {
  value: { [key in ActionItemType]: any };
  row: object;
}) => {
  const getActionObject = (type: ActionItemType) => {
    let typeObject = value[type];

    const getRouteParaString = () =>
      `${entityType}/${row[typeObject["idFieldName"]] || row["id"]}`;

    switch (type) {
      case "view":
        return {
          label: typeObject.label,
          route: `/view/${getRouteParaString()}`,
        };
      case "edit":
        return {
          label: typeObject.label,
          route: `/edit/${getRouteParaString()}`,
        };
      case "editEntity":
        return {
          label: typeObject.label,
          route: `/edit/${typeObject["entityType"]}/${typeObject["id"]}`,
        };
      case "grid":
        return typeObject;

      default:
        return {};
    }
  };

  const options = Object.keys(value).map((key) => {
    const actionObject = getActionObject(key as ActionItemType);
    return { type: key, actionObject };
  });

  return options.map(({ type, actionObject }) => (
    <div>
      {
        <ActionItem
          type={type as ActionItemType}
          actionObject={actionObject}
          component={"link"}
        />
      }
    </div>
  ));
};
