import React, {useEffect, useState} from 'react';
import Mslect from "./MSelect";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Fields} from "@rjsf/material-ui"

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginBottom: 10,
        marginTop: 10

    },

}));


const {TitleField} = Fields

// @ts-ignore
const DependencyDropdowns = (props) => {
    const classes = useStyles();
    const {schema: {properties, title, config}} = props
    const nameList = Object.keys(properties)
    const [formData, setFormData] = useState(props.formData)
    const [combinationList, setCombinationList] = useState(config)


    const onChange = (key: string) => {
        // @ts-ignore
        return (event) => {

            setCombinationList(getCombinationList(key, event.target.value))

            if (key === nameList[0] && formData[key]) {

                setFormData({
                    [key]: event.target.value
                });

                return
            }

            setFormData({
                ...formData,
                [key]: event.target.value
            });


        };
    }


    const getCombinationList = (key: string, value: string) => {

        if (key === nameList[0]) {

            return [...config].filter((item) => item[key] === value)
        }

        return [...combinationList].filter((item) => item[key] === value)

    }


    useEffect(() => {
        props.onChange(formData)
    }, [formData])

    return (
        <div>
            {title && (
                // @ts-ignore
                <TitleField
                    title={title}
                    required={true}
                />
            )}

            {nameList.map((key, index) => {
                // @ts-ignore
                const options = index === 0 ? [...new Set([...config].map((item) => item[key]))] : [...new Set([...combinationList].map((item) => item[key]))]

                return (
                    <div className={classes.wrapper}>
                        < Mslect
                            label={properties[key].title}
                            options={options}
                            onChange={onChange(key)}
                            value={formData[key] ? formData[key] : ''}
                            disabled={false}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default DependencyDropdowns