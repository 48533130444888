import React from "react";
import nbnFixedWirelessProductEssentials from "../../media/helpPDF/FSG nbn Fixed Wireless Product Essentials.pdf";
import nbnTC4FTTNProductEssentials from "../../media/helpPDF/FSG nbn TC4 FTTN Product Essentials.pdf";
import nbnTC4FTTPProductEssentials from "../../media/helpPDF/FSG nbn TC4 FTTP Product Essentials.pdf";
import nbnTC4HFCProductEssentials from "../../media/helpPDF/FSG nbn TC4 HFC Product Essentials.pdf";
import nbnTC4NonRecurringCosts from "../../media/helpPDF/FSG nbn TC4 Non-recurring Costs.pdf";
import SkyMusterProductEssentials from "../../media/helpPDF/FSG SkyMuster Product Essentials.pdf";
import FSGProvisioningTimes from "../../media/helpPDF/FSG Provisioning Times.pdf";
import ActionDropDown, { Option } from "../ActionComponent/ActionDropdown";

const HelpComponent = () => {
  const options: Option[] = [
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ Fixed Wireless Product Essentials",
        href: nbnFixedWirelessProductEssentials,
      },
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ TC4 FTTN Product Essentials",
        href: nbnTC4FTTNProductEssentials,
      },
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ TC4 FTTP Product Essentials",
        href: nbnTC4FTTPProductEssentials,
      },
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ TC4 HFC Product Essentials",
        href: nbnTC4HFCProductEssentials,
      },
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ SkyMuster Product Essentials",
        href: SkyMusterProductEssentials,
      },
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "nbn™ TC4 Non-recurring Costs",
        href: nbnTC4NonRecurringCosts,
      }
    },
    {
      type: "downLoadPDF",
      actionObject: {
        label: "FSG Provisioning Times",
        href: FSGProvisioningTimes,
      },
    },
    {
      type: "link",
      actionObject: {
        label: "Developer Tools - Beta API",
        route: "/swagger",
      },
    },
  ];

  return <ActionDropDown name="Resources" options={options} />;
};

export default HelpComponent;
