import { RowOption } from "../Menu";
import { MenuType, stringIndexObject } from "../../common/types";
import store from "../../app/store";
import { getSlice } from "../../features/helper";

const getReducerNames = (menu: Array<RowOption>) => {
  const reducerTypeArray = ["list"];

  const namesObject: stringIndexObject = {};

  const getNames = (_menu: Array<RowOption>) => {
    _menu.forEach((item) => {
      if ("children" in item) {
        return getNames(item.children);
      } else if (
        item.action_entity &&
        item.action_type &&
        reducerTypeArray.includes(item.action_type)
      ) {
        namesObject[item.action_entity] = item.action_type;
      }
    });
  };
  getNames(menu);
  return namesObject;
};

export const registerAsyncReducers = (menu: MenuType) => {
  const reducerNames = getReducerNames(Object.values(menu));
  Object.keys(reducerNames).forEach((item) => {
    const sliceName = `${item}${reducerNames[item]}`;

    // @ts-ignore
    store.injectReducer(sliceName, getSlice(sliceName).reducer);
  });
};
