import React, { useState } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
  },

  label: {
    marginBottom: 10,
  },
  dateWrapper: {
    display: "flex",
  },
  date: {
    borderBottom: "solid 1px",
    width: 150,
    textAlign: "center",
  },
  text: {
    marginLeft: 8,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginLeft: 8,
    cursor: "pointer",
  },
  pickerWrapper: {
    minWidth: 700,
    position: "absolute",
    top: 0,
    zIndex: 999,
  },
});

type DateRangePicker_Props = {
  label: string;
  columnName: string;
  dateObject: { lessThan: Date; greaterThan: Date };
  handleDateChange: (columnName: string, range: DateRange) => void;
};

const DateRangePicker_ = ({
  label,
  columnName,
  dateObject,
  handleDateChange,
}: DateRangePicker_Props) => {
  const classes = useStyles();
  const [showCalendar, setShowCalendar] = useState(false);
  const { lessThan: endDate, greaterThan: startDate } = dateObject;
  const toggle = () => setShowCalendar(!showCalendar);

  return (
    <div>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <div className={classes.dateWrapper}>
        <div className={classes.date}>{startDate && startDate}</div>
        <div className={classes.text}>to</div>
        <div className={classes.date}>{endDate && endDate}</div>
        <div onClick={() => setShowCalendar(true)} className={classes.icon}>
          <DateRangeIcon />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.pickerWrapper}>
          <DateRangePicker
            toggle={toggle}
            open={showCalendar}
            onChange={(range) => {
              // setDateRange(range)
              handleDateChange(columnName, range);
            }}
            closeOnClickOutside={true}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker_;
