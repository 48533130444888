import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const LoadingComponent = ({ text }: { text?: string }) => (
  <Container maxWidth="sm">
    <Box
      justifyContent={"center"}
      display="flex"
      flexDirection={"column"}
      mt={20}
      width="100%"
    >
      {text ? <h3>{text}</h3> : <h3>Loading...</h3>}
      {Array(10)
        .fill(1)
        .map((item, index) => (
          <Box my={2} key={index}>
            <Skeleton />
          </Box>
        ))}
    </Box>
  </Container>
);

export default LoadingComponent;
