import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Message from "./Message";
import React from "react";

const AccessDeniedView = () => {
  return (
    <Container maxWidth="lg">
      <h1>Access Denied</h1>
      <Box mt={2}>
        <Message message={"Access Denied"} type={"error"} />
      </Box>
    </Container>
  );
};
export default AccessDeniedView;
