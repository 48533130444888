import React from "react";
import { Button } from "@material-ui/core";

const Fallback = (
  <p style={{ marginTop: "50px", textAlign: "center" }}>
    <span style={{ marginLeft: "10px" }}>An error has occurred</span>
    <Button href="/" color="primary">
      Go to DashBoard
    </Button>
  </p>
);

class ErrorBoundary extends React.Component {
  state = { error: null };

  // this method can receive error
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return Fallback;
    }
    return children;
  }
}

export default ErrorBoundary;

// https://github.com/bvaughn/react-error-boundary
