import React from "react";
import { WidgetProps } from "@rjsf/core";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import { Modify } from "../../common/types";

type NewSchema = Modify<
  WidgetProps["schema"],
  { title: AlertProps["severity"] }
>;
type AlertWidgetProps = Modify<WidgetProps, { schema: NewSchema }>;

const AlertWidget = (props: AlertWidgetProps) => {
  const {
    schema: { title },
    value,
  } = props;

  return (
    <>
      {
        <Alert severity={title}>
          <p dangerouslySetInnerHTML={{ __html: value }} />
        </Alert>
      }
    </>
  );
};

export default AlertWidget;
