import { AppDispatch } from "../../common/types";
import BaseAPI from "../../service/api/BaseAPI";
import {
  dashboardLoading,
  fetchDashboardSuccess,
  fetchedUser,
  loadingError,
} from "./dashboardSlice";

export const fetchDashboard = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(dashboardLoading());

    try {
      const user = await BaseAPI.get(`/user`);
      console.log("FETCheD", user);
      let payload = {};
      if (user.data) {
        payload = user.data;
      }
      dispatch(fetchDashboardSuccess(payload));

      dispatch(
        fetchedUser({
          name: user.data.name,
          organisation: user.data.organisation,
        })
      );
    } catch (err) {
      dispatch(loadingError(err));
    }
  };
};
