import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    minWidth: 340,
    display: "flex",
    width: "100%",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardTextWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 0,
    flexGrow: 1,
  },
  cardText: {
    textAlign: "center",
    fontSize: 100,
  },
});

const CasesCard = ({ openCaseCount }: { openCaseCount: number }) => {
  const { root, cardContent, cardTextWrapper, cardText } = useStyles();
  const history = useHistory();
  const onClick = () => {
    history.push("/list/case");
  };

  return (
    <Card className={root} onClick={onClick}>
      <CardContent className={cardContent}>
        <h2>Cases</h2>
        <div className={cardTextWrapper}>
          <p className={cardText}>{openCaseCount}</p>
        </div>
        <p>Open support cases</p>
      </CardContent>
    </Card>
  );
};

export default CasesCard;
