import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { AlertProps, AlertTitle } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 340,
    display: "flex",
    width: "100%",
    "& li": {
      listStyleType: "none",
    },
  },
  cardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleStyle: {
    marginBottom: 0,
    marginRight: 16,
  },
  cardTextWrapper: {
    paddingTop: 20,
  },
  alert: {
    marginTop: 16,
  },
  detailStyle: {
    marginBottom: 16,
  },
});

interface NoticesData {
  entityType: "string";
  items: Array<Notice>;
}

interface Notice {
  title: string;
  timestamp: string;
  entityType: string;
  id: string;
  status: AlertProps["severity"];
  detail?: string;
}

const NoticesCard = ({ data }: { data: NoticesData }) => {
  const { items } = data;
  const {
    root,
    titleStyle,
    cardContent,
    titleWrapper,
    cardTextWrapper,
    alert,
    detailStyle,
  } = useStyles();

  return (
    <Card className={root}>
      <CardContent className={cardContent}>
        <div className={titleWrapper}>
          <h2 className={titleStyle}>Notifications</h2>
          <Link to={"/list/event"}>View all</Link>
        </div>

        <div className={cardTextWrapper}>
          {items.map(
            ({ title, timestamp, entityType, id, status, detail }: Notice) => (
              <Link to={`/form/edit/${entityType}/${id}`}>
                <Alert severity={status} className={alert}>
                  <AlertTitle>{title}</AlertTitle>
                  {detail && (
                    <p
                      className={detailStyle}
                      dangerouslySetInnerHTML={{ __html: detail }}
                    />
                  )}
                  <span>{timestamp}</span>
                </Alert>
              </Link>
            )
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default NoticesCard;
