import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectFieldTemplateProps } from "@rjsf/core";

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    border: "solid 2px lightBlue",
    borderRadius: "5px",
  },
});

const AddressObjectTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}: ObjectFieldTemplateProps) => {
  const classes = useStyles();

  return (
    <>
      {(uiSchema["ui:title"] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element: any, index: number) => (
          <Grid item={true} xs={12} key={index}>
            {element.content}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AddressObjectTemplate;
