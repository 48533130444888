import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch } from "react-redux";
import { changeParam } from "../features/form/formSlice";
import MDialog from "./MDialog";

type StepperProps = {
  state: {
    "Search Address": {
      clickable: boolean;
      param: object;
      message: string;
    };
    Qualify: object;
    "Technology Selection": object;
    Configure: object;
    "Quote Complete": object;
    "Convert To Order": object;
  };
  currentState: "Configure";
};

type BarProps = {
  state: string;
  step: string;
  stepObject: {
    clickable?: boolean;
    param: object;
    message?: string;
  };
};

const useProgressTabStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& div:first-child": {
      marginLeft: 0,
    },
  },
}));

// @ts-ignore
const useBarStyles = makeStyles((theme) => {
  const { success, primary } = theme.palette;

  const backgroundColor = ({ state }: { state: string }) =>
    state === "done"
      ? success.dark
      : state === "toBeDone"
      ? "#dae7eb"
      : primary.dark;
  const color = ({ state }: { state: string }) =>
    state === "toBeDone" ? "#7978bd" : "white";
  const cursor = ({ clickable }: { clickable: boolean }) =>
    clickable ? "pointer" : "";
  const textDecoration = ({ clickable }: { clickable: boolean }) =>
    clickable ? "underline" : "";

  return {
    bar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor,
    },
    chip: {
      marginLeft: "-16px",
      marginTop: 8,
      marginBottom: 8,
      //clipPath: 'polygon(84% 0, 94% 50%, 84% 100%, 0% 100%, 10% 50%, 0% 0%)',
    },
    span1: {
      width: 20,
      height: 50,
      display: "inline-block",
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%, 100% 50%)",
      border: "none",
      backgroundColor,
      margin: "0 -1px 0 0px",
    },
    span2: {
      width: 20,
      height: 50,
      display: "inline-block",
      clipPath: "polygon(0 0, 0% 100%, 100% 50%)",
      border: "none",
      backgroundColor,
      margin: "0 0px 0 -1px",
    },
    barCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "none",
      fontSize: "16px",
      padding: "16px",
      height: "50px",
      backgroundColor,
      color,
    },
    text: {
      paddingLeft: 8,
      paddingRight: 8,
      textDecoration,
    },
  };
});

const getState = (index: number, currentStep: string, steps: string[]) => {
  const currentStepIndex = steps.findIndex((item) => item === currentStep);

  if (currentStep === "null") {
    return "done";
  }

  if (currentStepIndex >= 0) {
    const indexComparation = currentStepIndex - index;
    if (indexComparation < 0) {
      return "toBeDone";
    }

    if (indexComparation === 0) {
      return "current";
    }

    if (indexComparation > 0) {
      return "done";
    }
  }

  return "undefined";
};

const Bar = ({ state, step, stepObject }: BarProps) => {
  const { clickable, param, message } = stepObject;
  const classes = useBarStyles({ state, clickable });
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const onClick = () => {
    if (!clickable) {
      return;
    }
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const onContinue = () => {
    dispatch(changeParam(param));
  };

  return (
    <div className={classes.chip}>
      <div className={classes.bar} onClick={onClick}>
        <div className={classes.span1}></div>
        <div className={classes.barCenter}>
          <span className={classes.text}>{step}</span>
          {state === "done" && (
            <span>
              <CheckIcon />
            </span>
          )}
        </div>
        <div className={classes.span2}></div>
      </div>
      {clickable && (
        <MDialog
          dialogContent={<p>{message}</p>}
          open={showPopup}
          handleClose={handleClose}
          onConfirm={onContinue}
        />
      )}
    </div>
  );
};

const Stepper = ({ data }: { data: StepperProps }) => {
  const { root } = useProgressTabStyles();
  const stateObject = data.state;
  const steps = Object.keys(stateObject);
  return (
    <div className={root}>
      {steps.map((step: string, index: number) => {
        const state = getState(index, data.currentState, steps);
        return (
          <Bar
            state={state}
            step={step}
            stepObject={stateObject[step]}
            key={`${step}${index}`}
          />
        );
      })}
    </div>
  );
};

export default Stepper;
