import React from "react";
import Form from "@rjsf/material-ui";
import { FormProps } from "@rjsf/core";
import FileUpload from "./FileUpload";
import { useParams } from "react-router";
import AddressObjectTemplate from "./AddressObjectTemplete";
import RoObjectTemplate from "./RoObjectTemplete";
import RadioTableWidget from "./Mradio";
import AddressAutoComplete from "./AddressAutoComplete";
import AutoComplete from "./AsyncAutoComplete";
import DependencyDropdowns from "./DependencyDropdowns";
import HtmlWidget from "./HtmlWidget";
import Rte from "./Rte";
import TableWidget from "./StandardTableTemplate";
import AlertWidget from "./AlertWidget";
import ErrorListTemplate from "./ErrorListTemplete";
import SchemaInput from "./SchemaInput";
import { State } from "./FormPage";

const log = (type: string) => console.log.bind(console, type);

const widgets = {
  FileWidget: FileUpload,
  TextWidget: SchemaInput,
  AutoComplete: AutoComplete,
  AddressAutoComplete: AddressAutoComplete,
  RadioTableWidget: RadioTableWidget,
  tableWidget: TableWidget,
  htmlWidget: HtmlWidget,
  rte: Rte,
  alertWidget: AlertWidget,
};

const fields = {
  DependencyDropdowns: DependencyDropdowns,
};

const getUiSchema = (state: State, baseSchema: FormProps<any>["uiSchema"]) => {
  return {
    ...baseSchema,
    "ui:readonly": state === "view" && true,
    service: {
      "ui:ObjectFieldTemplate": AddressObjectTemplate,
    },
    __ro: {
      "ui:ObjectFieldTemplate": RoObjectTemplate,
    },

    Toggle: {
      "ui:widget": "RadioTableWidget",
    },
    ss: {
      "ui:field": "DependencyDropdowns",
    },

    gPlaces: {
      "ui:widget": "AddressAutoComplete",
    },
  };
};

type BaseForm = {
  schema: FormProps<any>["schema"];
  uiSchema: FormProps<any>["uiSchema"];
  formData: FormProps<any>["formData"];
  setFormData: Function;
  onSubmit: FormProps<any>["onSubmit"];
  children: FormProps<any>["children"];
  transformErrors: FormProps<any>["transformErrors"];
};

const BaseForm = ({
  schema,
  uiSchema,
  formData,
  setFormData,
  onSubmit,
  children,
  transformErrors,
}: BaseForm) => {
  const { state } = useParams();

  return (
    <Form
      schema={{
        ...schema,
      }}
      uiSchema={getUiSchema(state, uiSchema)}
      // @ts-ignore
      widgets={widgets}
      fields={fields}
      onChange={({ formData }) => {
        setFormData(formData);
      }}
      onSubmit={onSubmit}
      onError={log("errors")}
      formData={formData}
      transformErrors={transformErrors}
      showErrorList
      ErrorList={ErrorListTemplate}
      noHtml5Validate
      //liveValidate={true}
    >
      {children}
    </Form>
  );
};

export default BaseForm;
//https://github.com/rjsf-team/react-jsonschema-form/issues/1602
//https://www.digitalocean.com/community/tutorials/js-file-reader

/*   const ss = {
        title: "DependencyDropdown",
        type: "object",
        config: [
    {t1: "option #0", t2: "option #01", t3: "option #011"},
    {t1: "option #0", t2: "option #01", t3: "option #012"},
    {t1: "option #0", t2: "option #02", t3: "option #013"},
    {t1: "option #1", t2: "option #02", t3: "option #012"},
    {t1: "option #1", t2: "option #03", t3: "option #012"},
    {t1: "option #1", t2: "option #02", t3: "option #012"},
    {t1: "option #2", t2: "option #03", t3: "option #013"},
    {t1: "option #2", t2: "option #04", t3: "option #014"},
        ],
        properties: {
        jj: {
        type: "object",
        required: ["t1", "t2"],
        properties: {
        t1: {
        title: "t1",
        type: "string",
    },
        t2: {
        title: "t2",
        type: "string",
    },
        t3: {
        title: "t3",
        type: "string",
    },
    },
    },
    },
    };


        const def = {
        "properties": {
        "Toggle": {"$ref": "#/definitions/Toggle"},
        "gPlaces": {
        title: "Add location",
        type: "string"
    }
    },
        "definitions": {
        "Toggle": {
        "title": "Toggle",
        "type": "string",
        "cols": [
        "Monthly",

        ],
        "oneOf": [

    {
        "title": "Enable",
        "type": "string",
        "const": 'qqq',
        "subtitle": "500 Mb/s Wholesale Layer 3",
        "Monthly": "$795.91",
        "Installation": "$5499.00",
        "Fibre Build Cost": "$1000",


    },

    {
        "title": "able",
        "type": "string",
        "const": '333',
        "subtitle": "500 Mb/s Wholesale Layer 3",
        "Monthly": "$795.91",
        "Installation": "$5499.00",
        "Fibre Build Cost": "$1000",

    }
        ]
    }
    }
    }*/
