import withStyles from "@material-ui/core/styles/withStyles";
import Chip from "@material-ui/core/Chip";

const SquareChip = withStyles((theme) => ({
    root: {
        borderRadius:4
    },
}))(Chip);

export default SquareChip
