import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Page from "../../components/Page";
import NoticesCard from "../../components/NoticesCard";
import GaugeCard from "../../components/GaugeCard";
import CasesCard from "./CasesCard";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../features/dashboard/dashboardSlice";
import LoadingComponent from "../../components/LoadingComponent";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 1200,
  },
  cardContainer: {
    padding: 16,
  },
  item: {
    padding: 16,
    display: "flex",
    justifyContent: "center",
  },
  noticesCard: {},
});

const Dashboard: FunctionComponent = (props) => {
  const classes = useStyles();

  const { quoteGauge, orderGauge, notifications, openCaseCount } = useSelector(
    dashboardSelector
  );

  if (!quoteGauge)
    return (
      <Box textAlign="center">
        <LoadingComponent />
      </Box>
    );

  return (
    <Page>
      <Grid
        container
        direction="row"
        //justify="center"
        className={classes.root}
      >
        <Grid xs={12} md={6} lg={4} item className={classes.item}>
          {quoteGauge && <GaugeCard data={quoteGauge} />}
        </Grid>

        <Grid xs={12} md={6} lg={4} item className={classes.item}>
          {orderGauge && <GaugeCard data={orderGauge} />}
        </Grid>

        <Grid xs={12} md={6} lg={4} item className={classes.item}>
          {openCaseCount && <CasesCard openCaseCount={openCaseCount} />}
        </Grid>
        <Grid item xs={12} md={6} lg={8} className={classes.item}>
          {notifications && <NoticesCard data={notifications} />}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;

const dashboardC = {
  quoteGauge: {
    title: "Quotes",
    type: "gaugeWidget",
    amount: 600,
    pieArray: [100, 500, 1000],
    targetAmount: 1000,
    displayUnit: "currency",
    buttons: [
      {
        label: "New (15)",
        code: "NEW",
        link: {
          actionType: "list",
          entityType: "quote",
          filter: [["state", "eq", "new"]],
        },
      },
      {
        label: "In Progress (15)",
        code: "INPROGRESS",
        link: {
          actionType: "list",
          entityType: "eequote",
          params: {
            filter: [["state", "eq", "In Progress"]],
          },
        },
      },
    ],
  },
  notifications: {
    type: "noticeWidget",
    entityType: "service",
    items: [
      {
        status: "success",
        timestamp: "13-02-2021 08:00",
        title: "Service Created",
        detail: "158 central road, Avalon 2107",
        entityType: "service",
        id: "123123121213",
      },
      {
        status: "error",
        timestamp: "23-02-2021 08:00",
        title: "Service Outage",
        detail: "158 central road, Avalon 2107",
        entityType: "service",
        id: "123123121213",
      },
      {
        status: "warning",
        timestamp: "23-02-2021 08:00",
        title: "March Feb Quota at 50%",
        detail: "158 central road, Avalon 2107",
        entityType: "service",
        id: "123123121213",
      },
      {
        status: "success",
        timestamp: "23-02-2021 09:00",
        title: "Service Restored",
        detail: "158 central road, Avalon 2107",
        entityType: "service",
        id: "123123121213",
      },
      {
        status: "info",
        timestamp: "23-02-2021 08:00",
        title: "Case status changed",
        detail: "Case 18223 moved to <strong>In Progress</strong>",
        entityType: "case",
        id: "123123121213",
      },
    ],
  },
};
