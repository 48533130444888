import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    paddingTop: 30,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
});

type PageProps = { children: React.ReactNode };
const Page = ({ children }: PageProps) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default Page;
