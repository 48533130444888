import React, { CSSProperties } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { WidgetProps } from "@rjsf/core";
import FormLabel from "@material-ui/core/FormLabel";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 10,
    marginTop: 10,
  },
  table: {
    minWidth: 650,
    border: 2,
  },
}));

const HtmlWidget = (props: WidgetProps) => {
  const { table } = useStyles();
  const {
    label,
    schema: { title },
    value,
  } = props;

  const styles: CSSProperties = {
    verticalAlign: "top",
  };
  let rows = value.rows;
  let cols = value.cols;
  const isTable = false;

  return (
    <>
      {title && <FormLabel required={false}>{label || title}</FormLabel>}
      {!isTable && (
        <div dangerouslySetInnerHTML={{ __html: value }} style={styles}></div>
      )}
      {isTable && (
        <TableContainer component={Paper}>
          <Table className={table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {cols.map((col: any) => (
                  <TableCell>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default HtmlWidget;
