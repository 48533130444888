import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RichTextEditor, { EditorValue } from "react-rte";
import MFormLabel from "./MFormLabel";
import { WidgetProps } from "@rjsf/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 10,
    marginTop: 10,
  },
  reactRTE: {
    "& .public-DraftEditor-content": {
      minHeight: 200,
    },
  },
}));

const Rte = (props: WidgetProps) => {
  const classes = useStyles();
  const {
    label,
    required,
    schema: { title },
  } = props;
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const onChange = (value: EditorValue) => {
    setValue(value);
    props.onChange(value.toString("html"));
  };

  return (
    <>
      {title && <MFormLabel required={required}>{label || title}</MFormLabel>}

      <div className={classes.wrapper}>
        <RichTextEditor
          className={classes.reactRTE}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};
export default Rte;
