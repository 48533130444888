import React from "react";
import { RoundButton } from "../Buttons";
import { Badge, Button } from "@material-ui/core";

type ButtonProps = {
  label: string;
  href: string;
};

export const getChip = (code: string, count: number, index: number) => {
  switch (code) {
    case "NEW":
      return ({ label, href }: ButtonProps) => (
        <Badge badgeContent={count} color="primary" key={index}>
          <RoundButton
            variant={"outlined"}
            color="primary"
            href={href}
            size={"small"}
          >
            {code}
          </RoundButton>
        </Badge>
      );

    case "INPROGRESS":
      return ({ label, href }: ButtonProps) => (
        <Badge badgeContent={count} color="error" key={index}>
          <RoundButton variant={"outlined"} href={href} size={"small"}>
            {code}
          </RoundButton>
        </Badge>
      );

    case "IN PROGRESS":
      return ({ label, href }: ButtonProps) => (
        <Badge badgeContent={count} color="error" key={index}>
          <RoundButton variant={"outlined"} href={href} size={"small"}>
            {code}
          </RoundButton>
        </Badge>
      );

    case "COMPLETE":
      return ({ label, href }: ButtonProps) => (
        <Badge badgeContent={count} color="secondary" key={index}>
          <RoundButton
            variant={"outlined"}
            color="secondary"
            href={href}
            size={"small"}
          >
            {code}
          </RoundButton>
        </Badge>
      );

    default:
      return ({ label, href }: ButtonProps) => (
        <Button
          variant={"contained"}
          color="default"
          href={href}
          size={"small"}
          key={index}
        >
          {label}
        </Button>
      );
  }
};
