import React, { useState } from "react";
import Mslect from "../Form/MSelect";
import MDialog_ from "../MDialog";
import { postData } from "../../service/common";
import useRequest from "../../Hooks/useRequest";
import { ListState } from "../../common/types";
import MessageList, { MessageListProps } from "../MessageList";
import handleErrorMessage from "../../common/util/handleErrorMessage";

export type BulkActionProps = {
  data: {
    [index: string]: {
      label: string;
      selectAll: boolean;
      url: string;
      confirmMessage: string;
    };
  };
  listState: ListState;
};

const BulkActionSelect = ({ data, listState }: BulkActionProps) => {
  const [value, setValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [messageList, setMessageList] = useState<MessageListProps>({
    messageList: [],
    type: undefined,
  });
  const [disabled, setDisabled] = useState(true);
  const { selection, rows } = listState;
  const { loading, runRequest } = useRequest();
  const handleClose = () => {
    setShowModal(false);
    setValue("");
  };

  const getOptionsData = () => {
    const options = [];
    const optionLabels = {};
    for (let option in data) {
      options.push(option);
      optionLabels[option] = data[option].label;
    }
    return {
      options,
      optionLabels,
    };
  };

  const hasSelectedAll = () =>
    selection.length > 0 && selection.length === rows.length;

  const onChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = e.target.value;
    setValue(value as string);
    const { selectAll, confirmMessage } = data[value as string];
    setShowModal(true);

    if (selection.length === 0) {
      setMessageList({
        messageList: ["Please select at least one item"],
        type: "warning",
      });
      setDisabled(true);
      return;
    }

    if (hasSelectedAll() && !selectAll) {
      setMessageList({
        messageList: ["You cannot select all for this action"],
        type: "warning",
      });
    } else {
      setMessageList({
        messageList: [confirmMessage],
        type: "warning",
      });
      setDisabled(false);
    }
  };

  const onConfirm = () => {
    const { url } = data[value];

    let body;
    if (hasSelectedAll()) {
      body = "select all";
    } else body = selection;

    runRequest(postData(url, body))
      .then((data) => {
        setMessageList({
          messageList: [data.data.message || "Success"],
          type: "success",
        });
        setDisabled(true);
      })
      .catch((err) => {
        const errMessage = handleErrorMessage(err);
        setMessageList({
          messageList: errMessage,
          type: "error",
        });
        setDisabled(true);
      });
  };

  const { options, optionLabels } = getOptionsData();

  return (
    <div>
      <Mslect
        options={options}
        optionLabels={optionLabels}
        label={"Actions"}
        onChange={onChange}
        value={value}
      />
      <MDialog_
        dialogContent={
          <MessageList
            messageList={messageList.messageList}
            type={messageList.type}
          />
        }
        open={showModal}
        handleClose={handleClose}
        onConfirm={onConfirm}
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
};

export default BulkActionSelect;
