// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { configureStore } from "@reduxjs/toolkit";
import createReducer from "../features";

// Configure the store
// @ts-ignore
const getRootReducer = (asyncReducers) => (state, action) => {
  if (action.type === "auth/logout") {
    // check for action type
    state = undefined;
  }

  // @ts-ignore
  return createReducer(asyncReducers)(state, action);
};

const configureStore_ = () => {
  const store = configureStore({
    // @ts-ignore
    reducer: getRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  // Add a dictionary to keep track of the registered async reducers
  // @ts-ignore
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  // @ts-ignore
  store.injectReducer = (key, asyncReducer) => {
    // @ts-ignore
    store.asyncReducers[key] = asyncReducer;
    // @ts-ignore
    store.replaceReducer(getRootReducer(store.asyncReducers));
  };
  return store;
};

export default configureStore_();

/*export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
*/
