// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.

const debounce = () => {
  let id: NodeJS.Timeout | null;

  return function (func: () => void, time: number) {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(function () {
      id = null;
      func();
    }, time);
  };
};

export default debounce;
