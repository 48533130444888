import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export type LinkObject = { label: string; route: string; component?: string };

const LinkActionItem = ({
  label,
  route,
  component = "menuItem",
}: LinkObject) => {
  if (component === "link") {
    return <Link to={route}> {label} </Link>;
  } else {
    return (
      <MenuItem component={Link} to={route}>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
    );
  }
};

export default LinkActionItem;
