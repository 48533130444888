import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import ActionDropDown from "../ActionComponent/ActionDropdown";
import {
  buildQueryString,
  getfilterString,
  getSortQueryString,
} from "./Helper";
import { ListState } from "../../common/types";
import IconButton from "@material-ui/core/IconButton";
import { ExportActionProps } from "../ActionComponent/ExportActionItem";

type ExportListProps = {
  api: string;
  listParams: ListState;
  fileName: string;
};

const ExportList = ({ api, listParams, fileName }: ExportListProps) => {
  const { currentPage, pageSize, filters, sortBy } = listParams;
  const queryString = buildQueryString(
    api,
    currentPage,
    pageSize,
    getfilterString(filters),
    getSortQueryString(sortBy)
  );

  const data: {
    type: "export";
    actionObject: Omit<ExportActionProps, "handleClose">;
  }[] = [
    {
      type: "export",
      actionObject: {
        label: "JSON",
        api: queryString,
        fileType: "json",
        fileName,
      },
    },
    {
      type: "export",
      actionObject: {
        label: "CSV",
        api: queryString,
        fileType: "csv",
        fileName,
      },
    },
  ];

  return (
    <ActionDropDown
      options={data}
      actionContainer={
        <IconButton size="small">
          <GetAppIcon />
        </IconButton>
      }
    />
  );
};

export default ExportList;
