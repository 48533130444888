import {Auth} from 'aws-amplify';
import {createSlice} from '@reduxjs/toolkit'
import {AppDispatch, AppState} from '../../common/types'


type authState = {
    authState: string,
    user: any,
    email: string,
    error: string,
    loading: boolean,
    passwordReset: string

}

const initialState = {
    authState: '',
    user: null,
    email: "",
    error: "",
    loading: false,
    passwordReset: ''
};


const resetState = (state: authState) => {
    state.loading = false;
    state.error = ''
}

// A slice for recipes with our three reducers
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

        authInit: state => {
            return {
                ...initialState,
                authState: 'signIn'
            }
        },
        logout: state => {

        },
        authBeginLoading: state => {
            state.loading = true
        },
        authSignInSuccess: (state, {payload}) => {
            resetState(state)
            state.user = payload
            state.authState = 'authed'
        },

        authForgotPasswordSuccess: (state, {payload}) => {
            resetState(state)
            state.passwordReset = 'verifyCode'
            state.email = payload
        },

        authError: (state, {payload}) => {
            state.loading = false
            state.authState = 'signIn'
            state.error = payload.message || 'error'
        },

        changeAuthState: (state, {payload}) => {
            resetState(state)
            state.authState = payload

        },

        fetchAuthedUserSuccess: (state, {payload}) => {
            resetState(state)
            state.user = payload
            state.authState = "authed"

        },

        passwordResetError: (state, {payload}) => {
            state.passwordReset = 'error'
            state.loading = false
            state.error = payload.message || 'error'

        },
        verifyCodeSuccess: (state) => {
            resetState(state);
            state.passwordReset = 'success'

        },

    },
})

// Three actions generated from the slice
export const {
    authInit,
    logout,
    authBeginLoading,
    authSignInSuccess,
    authForgotPasswordSuccess,
    authError,
    changeAuthState,
    fetchAuthedUserSuccess,
    passwordResetError,
    verifyCodeSuccess
} = authSlice.actions

// selector
export const authSelector = (state: AppState) => state.auth


// The reducer
export default authSlice.reducer

//=============================================================================
//Actions
//=============================================================================
export type Actions = ReturnType<typeof fetchAuthedUserSuccess
    | typeof authInit
    | typeof authBeginLoading
    | typeof authError
    | typeof changeAuthState
    | typeof authSignInSuccess
    | typeof authForgotPasswordSuccess>

/*export const fetchAuthedUserSuccess = (user: CognitoUser) => ({
    type: 'AUTH_FETCH_AUTHED_USER',
    payload: user
});


export const authError = (err: any) => ({
    type: 'AUTH_SYSTEM_ERROR',
    payload: err.message || err
});

export const changeAuthState = (value: string) => ({
    type: 'AUTH_CHANGE_AUTH_STATE',
    payload: value
});

*/


//=============================================================================
//Async Operations
//=============================================================================
/*export const refreshToken = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: any, session: any) => {
                //const { idToken, refreshToken, accessToken } = session;
                // do whatever you want to do now :)
            });
        } catch (err) {
            dispatch(authError(err));
        }
    };
};
*/

export const fetchAuthedUser = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading());
        try {
            const user = await Auth.currentAuthenticatedUser();
            dispatch(fetchAuthedUserSuccess(user));

        } catch (err) {
            console.log(err)
            dispatch(signOut());
        }
    };
};

export const signOut = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(logout())
        dispatch(authInit());
        try {
            await Auth.signOut();

        } catch (err) {
            //No error message is set here.
            //If you set error message here, the message is always displayed.
            //dispatch(authError(err));
            console.log(err)
        }
    };
};


const checkUserGroup = (userGroups: string[]) => {

    const envString = process.env.REACT_APP_USER_GROUP
    const allowedGroups: string[] = envString ? envString.split(',') : []

    if (allowedGroups.length > 0) {
        for (let i = 0; i < userGroups.length; i++) {
            if (allowedGroups.includes(userGroups[i])) {
                return true
            }
        }
    }
    return false
}


export const signIn = (email: string, password: string) => {
    return async (dispatch: AppDispatch) => {

        dispatch(authBeginLoading());

        try {
            const user = await Auth.signIn(email, password);

            const userGroups = user.signInUserSession.idToken.payload['cognito:groups']
            if (checkUserGroup(userGroups)) {
                dispatch(authSignInSuccess(user));
            } else {
                await Auth.signOut();
                dispatch(authError({message: 'User group not allowed to sign in'}));
            }

        } catch (err) {
            dispatch(authError(err));

        }
    };
};


export const forgotPassword = (email: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading());
        try {
            await Auth.forgotPassword(email);
            dispatch(authForgotPasswordSuccess(email));
        } catch (err) {
            dispatch(passwordResetError(err));
        }
    };
};


export const forgotPasswordSubmit = (email: string, code: string, password: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading());
        try {
            await Auth.forgotPasswordSubmit(email, code, password);
            dispatch(verifyCodeSuccess());
        } catch (err) {
            dispatch(passwordResetError(err));
        }
    };
};


/*export const idleLogout = (timeOut:number, logOutUser:Function)=>{
    let t:number;

    window.addEventListener('load', resetTimer, true);
    const  events = ['scroll', 'mousedown', 'mousemove', 'keypress', 'touchstart']
    events.forEach(function(name) {
        document.addEventListener(name, resetTimer, true);
    });

    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(logOutUser, timeOut);  // time is in milliseconds
    }
}
*/
/*const idLeLogout=()=>{
    return async (dispatch: AppDispatch) => {
        if(!idTokenExpired) {
            return
        }
        if(idTokenExpired&&isOverIdleLimit(60000*30)){
            dispatch(signOut)
        }
    }
}
*/