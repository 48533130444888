import { colors, createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.grey,
      paper: colors.common.white,
    },
    primary: {
      main: "#1F6FEA",
    },
    secondary: {
      main: "#A5C72A",
    },
    error: {
      main: "#dc004e",
      dark: "#9a0036",
    },
    warning: {
      main: "#ff9800",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

export default theme;
