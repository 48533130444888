import {ChangeEvent, useState} from "react";
import {stringIndexObject} from "../common/types";


type EventProps = ChangeEvent<HTMLInputElement> | ChangeEvent<{ value: unknown }>


const useFormFields = (initialState: stringIndexObject) => {


    const [formFields, setFormFields] = useState(initialState)

    const handleChangeValue = (fieldName: string) => (event: EventProps) => {

        const newFormFields: stringIndexObject = {...formFields};

        newFormFields[fieldName] = event.target.value;
        setFormFields(newFormFields);
    };

    return {
        formFields,
        setFormFields,
        handleChangeValue
    }
}

export default useFormFields