import React, { Fragment } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { WidgetProps } from "@rjsf/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableHead } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Modify } from "../../common/types";

type NewSchema = WidgetProps["schema"] & {
  cols: string[];
  oneOf: { subtitle: string }[];
};
type RadioTableWidgetProps = Modify<WidgetProps, { schema: NewSchema }>;

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 16,
    "& td": {
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      border: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});

const RadioTableWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
}: RadioTableWidgetProps) => {
  const { enumOptions, enumDisabled } = options;
  const classes = useStyles();

  const _onChange = ({}, value: any) =>
    onChange(schema.type == "boolean" ? value !== "false" : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const row = options ? options.inline : false;

  const { cols } = schema;

  return (
    <div>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>

      <RadioGroup
        value={`${value}`}
        row={row as boolean}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      >
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {cols.map((item: string) => (
                  <TableCell>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {(enumOptions as any).map((option: any, i: number) => {
              const itemDisabled =
                enumDisabled &&
                (enumDisabled as any).indexOf(option.value) != -1;

              const radio = (
                <Fragment>
                  <FormControlLabel
                    control={<Radio color="primary" key={i} />}
                    label={`${option.label}`}
                    value={`${option.value}`}
                    key={i}
                    disabled={disabled || itemDisabled || readonly}
                  />
                </Fragment>
              );

              const data = schema.oneOf[i];
              const subtitle = data.subtitle;

              return (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    <p>{radio}</p>
                    {subtitle && <p>{subtitle}</p>}
                  </TableCell>

                  {cols.map((key: string) => (
                    <TableCell>{data[key]}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      </RadioGroup>
    </div>
  );
};

export default RadioTableWidget;
