import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";
import { ErrorListProps, AjvError } from "@rjsf/core";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    "& .MuiAlert-root": {
      padding: "0px 16px",
    },
  },
  titleStyle: {
    marginBottom: 8,
    color: theme.palette.error.main,
  },
  errorListWrapper: {
    // padding: '6px 0px',
    background: "rgb(255, 244, 229)",
  },
});

const ErrorListTemplate = (props: ErrorListProps) => {
  const { root, errorListWrapper, titleStyle } = useStyles();
  const { errors, schema } = props;
  console.log("props", props);
  const getPropertyName = (property: string) => {
    const convertedArray: string[] = property.split(".");
    const pathArray = convertedArray.slice(1, convertedArray.length);
    let _object = schema;
    let title;
    for (let i = 0; i < pathArray.length; i++) {
      if (!_object) {
        title = "Schema error";
        break;
      }
      const propertiesObject = _object.properties;
      if (propertiesObject) {
        // @ts-ignore
        _object = propertiesObject[pathArray[i]];
      }
    }

    if (_object) {
      title = _object.title;
    }
    return title;
  };

  // Error properties that will be ignored and not be displayed on the form
  const errorPropertiesIgnored = [
    ".installationCharges.installationChargesConsent",
  ];
  // Filtered errors that don't have the error properties ignored
  const errorsFiltered: AjvError[] = [];
  errors.forEach((error) => {
    if (!errorPropertiesIgnored.includes(error.property))
      errorsFiltered.push(error);
  });
  if (errorsFiltered.length === 0) return <div className={root}></div>;

  return (
    <div className={root}>
      <h3 className={titleStyle}>Please correct form errors below</h3>
      <ul className={errorListWrapper}>
        {errorsFiltered.map((error) => {
          const { property, message } = error;
          const title = getPropertyName(property);

          if (!title) {
            return null;
          }

          const errorMessage =
            title === "Schema error"
              ? "An error occurred while submitting the form"
              : `${title} ${message}`;

          return (
            <Alert severity={"warning"}>
              <p>{errorMessage}</p>
            </Alert>
          );
        })}
      </ul>
    </div>
  );
};

export default ErrorListTemplate;
