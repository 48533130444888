import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { WidgetProps } from "@rjsf/core";
import FormLabel from "@material-ui/core/FormLabel";
import {
  Link,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 10,
    marginTop: 10,
  },
  table: {
    minWidth: 650,
    border: 2,
  },
}));

const TableWidget = (props: WidgetProps) => {
  const classes = useStyles();
  const {
    label,
    schema: { title },
    value,
  } = props;

  let tableObject;
  const type = typeof value;
  if (type === "string") {
    tableObject = JSON.parse(value);
  } else {
    tableObject = value;
  }
  const { rows, cols, idCol } = tableObject;

  return (
    <>
      {title && <FormLabel required={false}>{label || title}</FormLabel>}
      {
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {cols.map((col: any, index: number) => (
                  <TableCell key={index}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any, index: number) => (
                <TableRow key={index}>
                  {cols.map((col: any, index: number) => {
                    if (col === idCol) {
                      return (
                        <TableCell key={index}>
                          <Link href={row.__url}>{row[col]}</Link>
                        </TableCell>
                      );
                    } else if (row[col] && row[col]["list"]) {
                      return (
                        <TableCell key={index}>
                          {row[col].list.map((string: any, index: number) => {
                            return <List key={index}>{string}</List>;
                          })}
                        </TableCell>
                      );
                    } else if (row[col]) {
                      return <TableCell key={index}>{row[col]}</TableCell>;
                    } else {
                      return <TableCell key={index}></TableCell>;
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  );
};

export default TableWidget;

/*
* const tableObject={rows:[{__url:'ss',1:{list:['jj','mm']},2:'dd'},{__url:'33',1:'jj',2:'dd'}], cols:[1,2]}
const test={
  title:'test',
  type:'string',
  default:JSON.stringify(tableObject)
}
*
* */
