import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {WidgetProps} from "@rjsf/core";

const parse = require('autosuggest-highlight/parse');

const loadScript = (src: string, position: HTMLHeadElement | null, id: string) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

type options = Array<{ structured_formatting: { secondary_text: string } }> | []


const AddressAutoComplete = (props: WidgetProps) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<options>([]);
    const loaded = React.useRef(false);
    const {label, onChange} = props
    const key = process.env.REACT_APP_GOOGLE_KEY
    const placeDetailService=document.createElement('div')

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {

            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;

    }

    const fetch = (request: any, callback: any) => {

        // @ts-ignore
        autocompleteService.current.getPlacePredictions(request, callback);
    }

    const setDetail = (placeId: string) => {
        // @ts-ignore
        const service = new window.google.maps.places.PlacesService( placeDetailService );
        const request = {
            placeId
        };

        const callback = (place: any, status: any) => {
            // @ts-ignore
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                onChange(JSON.stringify(place))
            }
        }
        service.getDetails(request, callback);

    }


    useEffect(() => {
        let active = true;

        // @ts-ignore
        if (!autocompleteService.current && window.google) {
            // @ts-ignore
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            // @ts-ignore
            setOptions(value ? [value] : []);
            return undefined;
        }

        const request = {
            input: inputValue,
            componentRestrictions: {country: 'au'},
        };

        // @ts-ignore
        fetch(request, (results) => {
            if (active) {
                // @ts-ignore
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    // @ts-ignore
                    newOptions = [...newOptions, ...results];
                }
                // @ts-ignore
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue]);



    return (
        <Autocomplete
            // @ts-ignore
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                // @ts-ignore
                setOptions(newValue ? [newValue, ...options] : options);
                // @ts-ignore
                setValue(newValue);
                if(newValue){
                    // @ts-ignore
                const placeId = newValue.place_id;
                setDetail(placeId)}
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} fullWidth/>
            )}
            renderOption={(option) => {
                // @ts-ignore
                const matches = option.structured_formatting.main_text_matched_substrings;
                // @ts-ignore
                const parts = parse(
                    // @ts-ignore
                    option.structured_formatting.main_text,
                    // @ts-ignore
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );


                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                            {parts.map((part: { highlight: any; text: React.ReactNode; }, index: number) =>
                                <span key={index}>{part.text}</span>
                            )}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

export default AddressAutoComplete
