import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import { authSelector, fetchAuthedUser } from "./features/auth/authSlice";
import Dashboard from "./views/dashboard";
import Header from "./components/Header";
import PrivateRoute from "./components/ProtectedRoute";
import SignIn from "./views/auth/SignIn";
import PasswordReset from "./views/auth/PasswordReset";
import PasswordResetCodeValidation from "./views/auth/PasswordResetCodeValidation";
import GridListContainer from "./components/Table/GridListContainer";
import User from "./views/user";
import { fetchDashboard } from "./features/dashboard/actions";
import ErrorBoundary from "./components/ErrorBundaries";
import QuotePDF from "./components/QuotePDF";
import LoadingComponent from "./components/LoadingComponent";
import FormPage from "./components/Form/FormPage";
import { Swagger } from "./components/swagger/Swagger";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  container: {
    background: theme.palette.background.default,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authState } = useSelector(authSelector);

  useEffect(() => {
    dispatch(fetchAuthedUser());
  }, []);

  useEffect(() => {
    if (authState === "authed") {
      dispatch(fetchDashboard());
    }
  }, [authState]);

  if (!authState) {
    return <LoadingComponent />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Box className={classes.root}>
        <Router>
          <ErrorBoundary>
            <Header />
            <Container maxWidth={false} className={classes.container}>
              <Switch>
                <Route path="/login">
                  <SignIn />
                </Route>
                <Route path="/swagger">
                  <Swagger />
                </Route>
                <PrivateRoute exact path="/" children={<Dashboard />} />
                <PrivateRoute
                  exact
                  path="/form/:state/:entityType/:id"
                  children={<FormPage />}
                />
                <PrivateRoute
                  exact
                  path="/form/:state/:entityType"
                  children={<FormPage />}
                />
                <PrivateRoute
                  exact
                  path="/list/:name"
                  children={<GridListContainer />}
                />
                <PrivateRoute path="/account" children={<User />} />
                <PrivateRoute path="/quote-pdf" children={<QuotePDF />} />
                <Route path="/request-password-reset">
                  <PasswordReset />
                </Route>
                <Route path="/password-reset-code-validation">
                  <PasswordResetCodeValidation />
                </Route>
              </Switch>
            </Container>
          </ErrorBoundary>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;
