import React, { Fragment, MouseEventHandler, useRef, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { reloadForm } from "../features/form/formSlice";
import useFormFields from "../Hooks/useFormFields";
import { postData } from "../service/common";
import useRequest from "../Hooks/useRequest";
import SubmitButton from "./Form/SubmitButton";
import MessageList, { MessageListProps } from "./MessageList";
import handleErrorMessage from "../common/util/handleErrorMessage";

export type EntityCancelButtonProps = {
  title: string;
  entityType: string;
  id: string;
  buttonLabel: string;
};

type ConfirmCancelProps = {
  onChange: Function;
  value: string;
  disableConfirm: boolean;
  onCancel: MouseEventHandler;
  submitting: boolean;
  className: string;
};

const useStyles = makeStyles((theme) => ({
  titleSection: {
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "lightgray",
  },
  redButton: {
    width: "auto",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const ConfirmCancel = ({
  onChange,
  value,
  disableConfirm,
  onCancel,
  submitting,
  className,
}: ConfirmCancelProps) => (
  <>
    <p>To cancel, type 'cancel' in the field below and click Confirm</p>
    <Box mt={2}>
      <TextField
        onChange={onChange("confirmInput")}
        value={value}
        variant="outlined"
        required
        size={"small"}
        fullWidth
      />
    </Box>
    <Box my={2} display="flex" justifyContent={"flex-end"}>
      <SubmitButton
        buttonProps={{
          onClick: onCancel,
          disabled: disableConfirm || submitting,
        }}
        submitting={submitting}
        className={className}
      >
        Confirm
      </SubmitButton>
    </Box>
  </>
);

const EntityCancelButton = ({
  title,
  entityType,
  id,
  buttonLabel,
}: EntityCancelButtonProps) => {
  const { titleSection, redButton } = useStyles();
  const dispatch = useDispatch();
  const { formFields, handleChangeValue, setFormFields } = useFormFields({
    confirmInput: "",
  });
  const { confirmInput } = formFields;
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [messageList, setMessageList] = useState<MessageListProps>({
    messageList: [],
    type: undefined,
  });
  const { messageList: messageList_, type } = messageList;
  const [open, setOpen] = useState(false);
  const { loading, runRequest } = useRequest();
  let token = useRef(null);

  const clickButton = async () => {
    setOpen(true);
    const url = `/workflow/cancel/token/${entityType}/${id}`;

    try {
      const data = await postData(url, {});

      token.current = data.data;
    } catch (e) {
      console.log(e);
    }
  };

  const closeModal = () => {
    setOpen(false);
    clearState();
  };

  const clearState = () => {
    setFormFields({ confirmInput: "" });
    setConfirmCancel(false);
    setMessageList({
      messageList: [],
      type: undefined,
    });
  };

  const onCancel = async () => {
    const _token = token.current;
    if (!token) {
      return;
    }

    const url = `/workflow/cancel/confirm/${entityType}/${id}/${_token}`;

    runRequest(postData(url, {}))
      .then((data) => {
        setConfirmCancel(true);
        setMessageList({
          messageList: [data.data.message || "Successfully Cancelled"],
          type: "success",
        });
      })
      .catch((err) => {
        setConfirmCancel(true);
        const errMessage = handleErrorMessage(err);
        setMessageList({
          messageList: errMessage,
          type: "error",
        });
      });
  };

  const clickClose = () => {
    closeModal();
    if (type === "success") {
      dispatch(reloadForm(true));
    }
  };

  const content = confirmCancel ? (
    <MessageList messageList={messageList_} type={type} />
  ) : (
    <ConfirmCancel
      onChange={handleChangeValue}
      value={confirmInput}
      onCancel={onCancel}
      disableConfirm={confirmInput !== "cancel"}
      submitting={loading}
      className={redButton}
    />
  );

  return (
    <Fragment>
      <Button
        variant={"contained"}
        color={"default"}
        fullWidth
        onClick={clickButton}
      >
        {buttonLabel}
      </Button>

      <Dialog
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <Box className={titleSection}>
          <h3>{title}</h3>
          <Button onClick={clickClose} color="primary">
            <CloseIcon />
          </Button>
        </Box>
        <Box mb={2}>
          <DialogContent>{content}</DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
};

export default EntityCancelButton;
