import { createSlice } from "@reduxjs/toolkit";
import { ListState } from "../common/types";

const initialState: ListState = {
  rows: [],
  currentPage: 0,
  columns: [],
  pageSize: 20,
  totalCount: 0,
  loading: false,
  lastQuery: "",
  filters: [],
  sortBy: [],
  entityType: "",
  filterConfig: {},
  selection: [],
  bulkAction: null,
};

export const getSlice = (sliceName: string) => {
  return createSlice({
    name: sliceName,
    initialState,
    reducers: {
      startLoading: (state, { payload }) => {
        return {
          ...state,
          page: payload.page,
          pageSize: payload.pageSize,
        };
      },

      loadingError: (state) => {
        return {
          ...state,
          loading: false,
        };
      },

      updateQuery: (state, { payload }) => {
        return {
          ...state,
          lastQuery: payload,
        };
      },

      updateRow: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        };
      },
      updatePage: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        };
      },
      updateFilters: (state, { payload }) => {
        return {
          ...state,
          ...payload,
          currentPage: 0,
        };
      },
      updateSortBy: (state, { payload }) => {
        return {
          ...state,
          ...payload,
          currentPage: 0,
        };
      },
      updateSelection: (state, { payload }) => {
        return {
          ...state,
          ...payload,
        };
      },
      fetchInit: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
    },
  });
};
