import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Box, Button, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSlice";
import { dashboardSelector } from "../../features/dashboard/dashboardSlice";

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  p: {
    padding: 15,
  },
  avatar: {
    height: 50,
    width: 50,
  },
}));
// @ts-ignore
const ProfileDetails = ({ email, name, organisation }) => {
  const classes = useStyles();

  return (
    <Box>
      <Avatar className={classes.avatar} title={name} />
      <Box
        display={"flex"}
        padding={1}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"left"}
      >
        <p>
          <strong>Name:</strong> {name}
        </p>
      </Box>
      <Box
        display={"flex"}
        padding={1}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"left"}
      >
        <p>
          <strong>Organisation:</strong> {organisation}
        </p>
      </Box>
      <Box
        display={"flex"}
        padding={1}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"left"}
      >
        <p>
          <strong>Email Address:</strong> {email}
        </p>
      </Box>
      <Box width="100%" my={2} display="flex" justifyContent="flex-end">
        <Box display="flex" flexDirection="column" p={2}>
          <Button
            component={Link}
            to={"/request-password-reset"}
            variant={"contained"}
            color={"secondary"}
          >
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ProfileDetailContainer = () => {
  const { name, organisation } = useSelector(dashboardSelector);

  const { user } = useSelector(authSelector);
  const { username } = user;
  return (
    <ProfileDetails email={username} name={name} organisation={organisation} />
  );
};

export default ProfileDetailContainer;
