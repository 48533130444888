import { useDispatch } from "react-redux";
import React, { useState } from "react";
import useFormFields from "../../../Hooks/useFormFields";
import { Box, Button, Card, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateRangePicker_ from "./DateRangePicker";
import Mslect from "../../Form/MSelect";
import {
  FilterConfigType,
  ListActions,
  ListState,
  stringIndexObject,
} from "../../../common/types";
import { DateRange } from "materialui-daterange-picker";

const useStyles = makeStyles({
  card: {
    width: "100%",
    overflow: "visible",
  },
  filterSectionWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
  },
  keyWordSearchWrapper: {
    width: 300,
  },
  filterWrapper: {
    margin: "8px 20px 8px 20px",
    "& .MuiFormLabel-root": {
      whiteSpace: "nowrap",
      fontSize: 14,
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  leftButton: {
    marginRight: 16,
  },
});

type FilterSectionProps = {
  updateFilters: ListActions["updateFilters"];
  filterConfig: ListState["filterConfig"];
};

const formatDate = (d: string | number | Date | undefined) => {
  return d ? new Date(d).toLocaleString().slice(0, 10) : d;
};

const FilterSection = ({ updateFilters, filterConfig }: FilterSectionProps) => {
  const {
    card,
    filterSectionWrap,
    filterWrapper,
    buttonWrapper,
    leftButton,
    keyWordSearchWrapper,
  } = useStyles();
  const dispatch = useDispatch();
  const [dates, setDates] = useState<stringIndexObject>({});
  const { formFields, setFormFields, handleChangeValue } = useFormFields({});

  const handleDateChange = (columnName: string, dateRange: DateRange) => {
    const { startDate, endDate } = dateRange;
    setDates({
      ...dates,

      [columnName]: {
        lessThan: formatDate(endDate),
        greaterThan: formatDate(startDate),
      },
    });
  };

  const renderFilterComponent = (filterObject: FilterConfigType) => {
    const { columnName, label, component } = filterObject;
    const onChange = handleChangeValue(columnName);
    switch (component) {
      case "dropdown":
        return (
          <Mslect
            options={filterObject.options || []}
            onChange={onChange}
            label={label}
            value={formFields[columnName] || ""}
            disabled={false}
          />
        );

      case "input":
        return (
          <TextField
            label={label}
            value={formFields[columnName] || ""}
            onChange={onChange}
          />
        );

      case "dateRangePicker":
        return (
          <DateRangePicker_
            label={label}
            columnName={columnName}
            dateObject={dates[columnName] || {}}
            handleDateChange={handleDateChange}
          />
        );
      default:
        return null;
    }
  };

  const clearFilters = () => {
    setFormFields({});
    setDates({});
    dispatch(updateFilters({ filters: [] }));
  };

  const getFilters = () => {
    const filters: any[] = [];

    Object.keys(formFields).forEach((columnName) =>
      filters.push([columnName, "contains", formFields[columnName]])
    );

    Object.keys(dates).forEach((columnName) => {
      const dateObject = dates[columnName];
      const dateFilter = Object.keys(dateObject).map((key) => [
        columnName,
        key,
        dateObject[key],
      ]);
      filters.push(dateFilter);
    });

    for (let i = 0; i < filters.length - 1; i = i + 2) {
      filters.splice(i + 1, 0, "and");
    }

    return filters;
  };

  const submitFilters = () => {
    console.log("getFilters()", getFilters());
    dispatch(updateFilters({ filters: getFilters() }));
  };

  return (
    <Card className={card}>
      <Box py={3} px={3}>
        <div className={`${keyWordSearchWrapper} ${filterWrapper}`}>
          <TextField
            label={"Key Words"}
            value={formFields["global"] || ""}
            onChange={handleChangeValue("global")}
            fullWidth
          />
        </div>
        <div className={filterSectionWrap}>
          {Object.keys(filterConfig).map((property) => (
            <div className={filterWrapper}>
              {renderFilterComponent(filterConfig[property])}
            </div>
          ))}
        </div>
        <div className={buttonWrapper}>
          <Button
            onClick={submitFilters}
            color={"secondary"}
            variant={"outlined"}
            className={leftButton}
          >
            Apply Filter
          </Button>
          <Button onClick={clearFilters} color={"default"} variant={"outlined"}>
            Clear Filter
          </Button>
        </div>
      </Box>
    </Card>
  );
};

export default FilterSection;

const _filterConfig = {
  _topState: {
    component: "dropdown",
    options: ["NEW", "COMPLETE"],
    columnName: "_topState",
    label: "State",
  },

  quoteNumber: {
    component: "input",
    columnName: "quoteNumber",
    label: "quoteNumber",
  },
  test3: {
    component: "dropdown",
    options: [1, 2, 3],
    columnName: "test3",
    label: "test3",
  },
  lastUpdated: {
    component: "dateRangePicker",
    columnName: "lastUpdated",
    label: "lastUpdated",
  },
};
