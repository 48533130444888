import { FormControl } from "@material-ui/core";
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Mselect = {
  options: Array<string | number>;
  optionLabels?: { [index: string]: string };
  onChange: SelectInputProps["onChange"];
  label: string;
  value: string | number;
  // variant: InputLabelProps['variant']
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    //marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    minWidth: 120,
    //width:'100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Mslect = ({
  options,
  optionLabels,
  onChange,
  label,
  value,
  disabled = false,
}: Mselect) => {
  const classes = useStyles();
  return (
    <FormControl
      className={classes.formControl}
      size="small"
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} label={label}>
        {options.map((option) => (
          <MenuItem value={option}>
            {optionLabels ? optionLabels[option] : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Mslect;
