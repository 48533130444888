import React from "react";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import fsglogo from "../media/images/fsglogo.png";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useLocation } from "react-router-dom";
// Create styles

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingLeft: 16,
    paddingRight: 16,
  },
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  tableRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "13%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "13%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
    color: "#484848",
    // margin: 5,
  },
  label: {
    fontWeight: 900,
    fontSize: 10,
    marginRight: 3,
  },
  detail: {
    fontSize: 10,
    color: "#484848",
  },
  topSection: {
    marginBottom: 20,
  },
  row: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
  titleStyle: {
    fontSize: 16,
  },
  titleSection: {
    marginBottom: 5,
    marginTop: 5,
  },
  companySection: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  image: {
    width: 200,
  },
  companyInfo: {
    fontSize: 10,
    color: "#484848",
  },
  companyName: {
    fontSize: 10,
    fontWeight: "bold",
  },
  topSectionRow: {
    display: "flex",
    flexDirection: "row",
  },
  grayRow: {
    backgroundColor: "#ddd",
  },
});

type QuotePDFProps = {
  title: string;
  info: { [index: string]: { label: string; detail: string } };
  columns: string[];
  rows: { item: { title: string; subtitle: string } }[];
};

const QuotePDF = ({ data }: { data: QuotePDFProps }) => {
  const { title, info, columns, rows } = data;

  const tableCol = (index: number) => {
    return { ...styles.tableCol, width: index === 0 ? "48%" : "13%" };
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.companySection}>
          <View style={styles.image}>
            <Image src={fsglogo} />
          </View>
          <View>
            <View>
              <Text style={styles.companyName}>
                Field Solutions Group Pty Ltd
              </Text>
            </View>
            <View style={styles.companyInfo}>
              <Text>ABN 62 155 490 074</Text>
            </View>
            <View style={styles.companyInfo}>
              <Text>Tel: 1300 000 477</Text>
            </View>
            <View style={styles.companyInfo}>
              <Text>38/23 Narabang Way Belrose NSW 2085</Text>
            </View>
            <View style={styles.companyInfo}>
              <Text>PO Box 269 Avalon Beach NSW 2107</Text>
            </View>
          </View>
        </View>
        <View style={styles.titleSection}>
          <Text style={styles.titleStyle}>{title}</Text>
        </View>
        <View style={styles.topSection}>
          {Object.values(info).map((item) => (
            <View style={styles.topSectionRow}>
              <View style={styles.label}>
                <Text>{item.label}</Text>
              </View>
              <View style={styles.detail}>
                <Text>{item.detail}</Text>
              </View>
            </View>
          ))}
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            {columns.map((item: string, index: number) => {
              return (
                <View style={tableCol(index)}>
                  <Text style={styles.tableCellHeader}>{item}</Text>
                </View>
              );
            })}
          </View>

          {rows.map((row: any, index: number) => {
            const backgroundColor = index % 2 === 0 ? "#ddd" : "white";
            const TitleCell = () => (
              <View style={tableCol(0)}>
                <Text style={styles.tableCell}>{row["item"]["title"]}</Text>
                <Text style={styles.tableCell}>{row["item"]["subtitle"]}</Text>
              </View>
            );

            return (
              <View style={{ ...styles.tableRow, backgroundColor }}>
                {columns.map((columnName: string, index: number) =>
                  index > 0 ? (
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row[columnName]}</Text>
                    </View>
                  ) : (
                    <TitleCell />
                  )
                )}
              </View>
            );
          })}
        </View>
        <View>
          <Text style={styles.detail}>
            Please Note: All prices are quoted Ex. GST and are subject to change
            without notice. Valid for 14 days from date of this quote.
          </Text>
          <Text style={{ fontSize: 10 }}>
            Unless otherwise stated, Enterprise Ethernet services will terminate
            at the local state FSG network PoP nearest to the service POI.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const QuotePDFWrapper = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const api = query.get("api");

  return (
    <Container maxWidth={"lg"}>
      <Box display={"flex"} flexDirection={"column"}>
        <PDFViewer height={"1000px"}>
          <QuotePDF data={data} />
        </PDFViewer>
      </Box>
    </Container>
  );
};

export default QuotePDFWrapper;

const data = {
  title: "Enterprise Ethernet Quote 1931605",
  info: {
    detail1: {
      label: "11",
      detail: "sdjfjffopg",
    },
    detail2: {
      label: "22",
      detail: "sdjfjffopg",
    },
  },
  columns: [
    "item",
    "monthly price",
    "install price",
    "price price",
    "price1 price1",
  ],
  rows: [
    {
      item: { title: "skskkskfkgkkhkhkhkhkhk", subtitle: "ssssdfff" },
      "install price": "499.99",
      "monthly price": "599.99",
      "price price": "499.99",
      "price1 price1": "599.99",
    },
  ],
};
