import React from "react";
import { Timeline } from "@material-ui/lab";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Typography } from "@material-ui/core";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Paper from "@material-ui/core/Paper";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreateIcon from "@material-ui/icons/Create";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import Note from "@material-ui/icons/Note";
import ClearIcon from "@material-ui/icons/Clear";
import theme from "../theme";

const iconMap = {
  create: <CreateIcon />,
  email: <MailOutlineIcon />,
  status: <TrackChangesIcon />,
  info: <VisibilityIcon />,
  comments: <ChatBubbleOutline />,
  note: <Note />,
  error: <ClearIcon />,
};

const timeLineDotColorMap = {
  success: theme.palette.success.main,
  error: theme.palette.error.main,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  oppositeContent: {
    flex: "none",
  },
  dot: {
    boxSizing: "content-box",
    width: 24,
    height: 24,
  },
}));

type data = Array<{
  timestamp: string;
  title: string;
  detail: string | null;
  code: string;
}>;

const MTimeLine = ({ data }: { data: data }) => {
  const classes = useStyles();

  return (
    <Timeline>
      {data.map((item) => {
        const { timestamp, code, detail, title } = item;
        const dotStyle = {
          backgroundColor: timeLineDotColorMap[code] || "gray",
        };
        return (
          <TimelineItem>
            <TimelineOppositeContent className={classes.oppositeContent}>
              <Typography variant="body2" color="textSecondary">
                {new Date(timestamp).toLocaleString()}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className={classes.dot} style={dotStyle}>
                {iconMap[code] ? iconMap[code] : null}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper className={classes.paper}>
                <h4>{title}</h4>
                {detail ? (
                  <p dangerouslySetInnerHTML={{ __html: detail }} />
                ) : null}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default MTimeLine;

//https://stackoverflow.com/questions/6525538/convert-utc-date-time-to-local-date-time
