import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../features/auth/authSlice";
import { Redirect } from "react-router";

const PrivateRoute = ({
  children: Component,
  ...rest
}: React.PropsWithChildren<RouteProps>) => {
  const { authState } = useSelector(authSelector);

  if (authState !== "authed") {
    return <Redirect to="/login" />;
  }
  return <Route {...rest}>{Component} </Route>;
};

export default PrivateRoute;
//https://stackoverflow.com/questions/53688899/typescript-and-react-children-type
