import React from "react";
// @ts-ignore
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import store from "../../app/store";
import swagger from "./swagger.json";
import { fetchAuthedUser } from "../../features/auth/authSlice";

const getToken = async () => {
  await store.dispatch(fetchAuthedUser());
  const state = store.getState() as any;
  return (
    (state.auth?.user?.signInUserSession?.idToken.jwtToken as string) ?? ""
  );
};

swagger.basePath ="/beta";

export const Swagger = () => (
  <SwaggerUI
    spec={swagger}
    onComplete={async (swaggerUi: any) => {
      const token = await getToken();
      swaggerUi.preauthorizeApiKey("Bearer", token);
    }}
  />
);
