export type HandleErrorProps = {
  response?: {
    data: {
      message?: {
        message: string;
        errors: { code: number; message: string }[];
      };
    };
    status: string | number;
  };
  message: string;
};

const handleErrorMessage = (err: HandleErrorProps) => {
  if (err.response) {
    if (err.response.status === 403) {
      return ["Access Denied"];
    }
    console.log("schema error", err.response.data, err.response.status);
    const message = err.response.data.message;
    if (message) {
      if (message.errors) {
        return message.errors.map((item) => item.message);
      }
      return [message.message];
    } else return ["error"];
  } else return [err.message];
};

export default handleErrorMessage;
