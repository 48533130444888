import BaseAPI from "../api/BaseAPI";
import {API_URL} from "../api/apiUrl";

export const postData=(url:string, data: Object) => {

        return BaseAPI.post(url, data);

};

export const fetchData= async (url:string) => {

     return BaseAPI.get(url);

};


export const deleteData= async (url:string) => {

    return  BaseAPI.delete(url);
};



export const deleteFile = (id:string)=>{

    const url=`${API_URL.FILE.DELETE}/${id}`
    return deleteData(url);

}


export const uploadFile = (data:object)=>{
    const url = API_URL.FILE.UPLOAD
    return postData(url,data);

}
