import { useEffect, useRef, useState } from "react";
import { fetchData, postData } from "../service/common";
import {
  changeParam,
  formSelector,
  reloadForm,
} from "../features/form/formSlice";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "./useRequest";
import handleErrorMessage from "../common/util/handleErrorMessage";
import { MessageListProps } from "../components/MessageList";
import { FormProps } from "@rjsf/core";
import { Widget } from "../components/MWidget";
import { ActionButtonProps } from "../components/Form/ButtonsGroups";

type Data = {
  UISchema: FormProps<any>["uiSchema"];
  JSONSchema: FormProps<any>["schema"];
  underWidgets: Widget["data"];
  overWidgets: Widget["data"];
  config?: {
    baseActions?: ActionButtonProps[];
    topActions?: ActionButtonProps[];
  };
};

const useSchemaForm = (
  submitUrl: string,
  schemaUrl: string,
  callback: Function
) => {
  const dispatch = useDispatch();
  const { param, shallReloadForm } = useSelector(formSelector);
  const [messageList, setMessageList] = useState<MessageListProps>({
    messageList: [],
    type: undefined,
  });
  const [data, setData] = useState<Data | null>(null);
  const [formData, setFormData] = useState<any>(null);
  const initialFormData = useRef(null);
  const omitValidationMap = {
    format: ["data-url"],
    properties: [
      ".gPlaces",
      ".services",
      ".tc4TechSummary",
      ".properties['other_attachments'].items.items.type",
      ".properties['other_attachments'].items.items",
      ".properties['other_attachments'].items",
      ".copperPairs",
    ],
  };
  const { loading, runRequest: loadForm } = useRequest();
  const { loading: submitting, runRequest: submitForm } = useRequest();

  const onSubmit = () => {
    submitForm(postData(submitUrl, formData))
      .then((data) => {
        setMessageList({
          messageList: [data.data.message || "Success"],
          type: "success",
        });

        if (callback) {
          callback(data);
        }
      })
      .catch((err) => {
        const errMessage = handleErrorMessage(err);
        setMessageList({
          messageList: errMessage,
          type: "error",
        });
      });
  };

  const transformErrors: FormProps<any>["transformErrors"] = (errors) => {
    console.error(errors);

    return errors
      .filter(
        (error) =>
          !(
            omitValidationMap.format.includes(error.params.format) ||
            omitValidationMap.properties.includes(error.property)
          )
      )
      .map((error) => {
        // Customise error messages
        if (
          error.property ===
            ".productOption.installationCharges.installationChargesConsent" &&
          error.name === "const" &&
          error.params?.allowedValue === true
        ) {
          error.message =
            "Please agree to cover potential installation charges by checking the box";
        }

        // Silence duplicated default error messages
        if (
          (error.property === ".productOption.productOption" &&
            ["const", "oneOf", "required"].includes(error.name)) ||
          (error.property === ".productOption" && error.name === "oneOf")
        ) {
          error.message = " "; // not set to empty string to avoid other error messages not showing
        }

        return error;
      });
  };

  const initializeState = () => {
    setData(null);
    setFormData(null);
    setMessageList({
      messageList: [],
      type: undefined,
    });
  };

  const getParamString = (param: string) => {
    let string;
    if (param) {
      string = `?${Object.keys(param).reduce(
        (a, b) => `${a}&${b}=${param[b]}`,
        ""
      )}`;
    } else {
      string = "";
    }
    //console.log('string', param, string)
    return string;
  };

  const getSchema = () => {
    initializeState();
    loadForm(fetchData(`${schemaUrl}${getParamString(param)}`))
      .then((data) => {
        const { formData, message } = data.data;
        const formData_ = formData ? formData : {};
        setData(data.data);
        setFormData(formData_);
        initialFormData.current = formData_;

        if (message) {
          setMessageList({ messageList: [message.text], type: message.type });
        }
      })
      .catch((err) => {
        const errMessage = handleErrorMessage(err);
        setMessageList({
          messageList: errMessage,
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (param) {
      getSchema();
      dispatch(changeParam(null));
    }
  }, [JSON.stringify(param)]);

  useEffect(() => {
    if (shallReloadForm) {
      getSchema();
      dispatch(reloadForm(false));
    }
  }, [shallReloadForm]);

  useEffect(() => {
    getSchema();
  }, [schemaUrl]);

  const { UISchema, JSONSchema, underWidgets, overWidgets, config } =
    data || {};

  return {
    messageList,
    setMessageList,
    schema: JSONSchema,
    uiSchema: UISchema,
    underWidgets,
    overWidgets,
    formData,
    setFormData,
    initialFormData,
    submitting,
    loading,
    onSubmit,
    transformErrors,
    config,
  };
};

export default useSchemaForm;
