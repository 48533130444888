import React, { PropsWithChildren } from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {},
}));

export type SubmitButtonProps = {
  submitting?: boolean;
  disabled?: boolean;
  className?: string;
  buttonProps?: ButtonProps;
};

const SubmitButton: React.FC<PropsWithChildren<SubmitButtonProps>> = (
  props
) => {
  const classes = useStyles();
  const { submitting, buttonProps, className, disabled } = props;
  const submittingIndicator = (
    <CircularProgress size={20} color="primary" className={classes.progress} />
  );

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      disabled={submitting || disabled}
      {...buttonProps}
      className={className ? className : classes.button}
    >
      {props.children || "Submit"}
      {submitting && submittingIndicator}
    </Button>
  );
};

export default SubmitButton;
