import { Alert, AlertProps } from "@material-ui/lab";

import React from "react";

const Message = ({
  message,
  type,
}: {
  message: string;
  type: AlertProps["severity"];
}) => {
  return <Alert severity={type}>{message}</Alert>;
};

export default Message;
