import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Gauge from "./GaugeChart";
import GaugeChart from "./GaugeChart";
import { Box } from "@material-ui/core";
import { getChip } from "./helper";

const useStyles = makeStyles({
  root: {
    // maxWidth: 500,
    width: "100%",
    minWidth: 340,
  },
  cardContent: {
    paddingBottom: 1,
  },
  cardActionsRow: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
  },
});

export type GuageCardProps = GaugeChart & {
  title: "string";
  buttons: Array<{
    label: string;
    code: string;
    count: number;
    link: { actionType: string; entityType: string };
  }>;
};

const GuageCard = ({ data }: { data: GuageCardProps }) => {
  const { title, buttons, amount, pieArray, targetAmount, displayUnit } = data;

  const classes = useStyles();
  let cnt = 0;
  if (buttons) {
    buttons.forEach((button) => {
      if (button["count"]) {
        cnt += button.count;
      }
    });
  }
  let newTitle = `${title}`;
  if (cnt > 0) {
    newTitle = `${cnt} ${title}`;
  }
  // Round amount to 1 decimal place
  const roundedAmount = Math.round(amount * 10) / 10;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <h2>{newTitle}</h2>
        <Box display="flex" justifyContent={"center"}>
          <Gauge
            amount={roundedAmount}
            pieArray={pieArray}
            targetAmount={targetAmount}
            displayUnit={displayUnit}
          />
        </Box>
        {buttons && (
          <CardActions className={classes.cardActionsRow}>
            {buttons.map(
              (
                { label, code, count, link: { actionType, entityType } },
                index: number
              ) =>
                getChip(
                  code,
                  count,
                  index
                )({ label, href: `/${actionType}/${entityType}` })
            )}
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
};

export default GuageCard;
const quoteGauge = {
  title: "Quotes",
  type: "gaugeWidget",
  amount: 600,
  pieArray: [100, 500, 1000],
  targetAmount: 1000,
  displayUnit: "currency",
  buttons: [
    {
      label: "New (15)",
      code: "NEW",
      link: {
        actionType: "list",
        entityType: "quote",
        filter: [["state", "eq", "new"]],
      },
    },
    {
      label: "In Progress (15)",
      code: "INPROGRESS",
      link: {
        actionType: "list",
        entityType: "eequote",
        params: {
          filter: [["state", "eq", "In Progress"]],
        },
      },
    },
  ],
};
