import React from "react";
import MTimeLine from "./TimeLine";
import Stepper from "./Stepper";
import { Box } from "@material-ui/core";
import MTreeView from "./TreeView";

export const WidgetsMap = {
  stepper: (values: any) => <Stepper data={values} />,
  timeline: (
    values: {
      timestamp: string;
      title: string;
      detail: string | null;
      code: string;
    }[]
  ) => <MTimeLine data={values} />,
  treeView: (values: { [index: string]: Object }) => (
    <MTreeView values={values} />
  ),
};

export type Widget = {
  data: Array<{ type: string; values: any }>;
  widgets: { [index: string]: (values: any) => JSX.Element };
};

const Widgets = ({ data, widgets }: Widget) => {
  return (
    <div>
      {data.map((item: { type: string; values: any }, index) => (
        <Box my={2} key={index}>
          {widgets[item.type](item.values)}
        </Box>
      ))}
    </div>
  );
};

export default Widgets;
