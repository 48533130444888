import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import MDialog from "../MDialog";
import ExportActionItem, { ExportActionProps } from "./ExportActionItem";
import LinkActionItem, { LinkObject } from "./LinkActionItem";
import DownloadPDFItem from "./DownloadPDFItem";

export type CancelAction = { label: string; text: string; api: string };
export type GridLink = { label: string; gridUrl: string };
export type EditEntity = { label: string; entityType: string; id: string };
export type DownloadPDFObject = { label: string; href: any };

export type actionObjectType =
  | CancelAction
  | LinkObject
  | GridLink
  | EditEntity
  | ExportActionProps
  | DownloadPDFObject;

export type ConfirmationAction = {
  label: string;
  text: string;
  clickConfirm: Function;
};

export type ActionItemType =
  | "link"
  | "list"
  | "new"
  | "view"
  | "edit"
  | "editEntity"
  | "export"
  | "downLoadPDF"
  | "cancel"
  | "grid";

type ActionItemProps = {
  type: ActionItemType;
  actionObject: actionObjectType;
  component?: string;
  handleClose?: () => void;
};

const routePath = {
  baseForm: "/form",
  gridList: "/list",
};

const ConfirmationActionItem = ({
  label,
  text,
  clickConfirm,
}: ConfirmationAction) => {
  const [showModal, setShowModal] = useState(false);

  const handleClickOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleConfirmation = () => {
    clickConfirm();
    handleClose();
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
      <MDialog
        dialogContent={<p>{text}</p>}
        open={showModal}
        handleClose={handleClose}
        onConfirm={handleConfirmation}
      />
    </div>
  );
};

export const CancelActionItem = ({ label, text, api }: CancelAction) => {
  const clickConfirm = () => {};

  return (
    <ConfirmationActionItem
      label={label}
      text={text}
      clickConfirm={clickConfirm}
    />
  );
};

const ActionItem = ({
  type,
  actionObject,
  component = "menuItem",
  handleClose,
}: ActionItemProps) => {
  const { baseForm } = routePath;
  const getLinkActionItem = (baseRoute?: string) => {
    if ("route" in actionObject) {
      const { label, route } = actionObject;
      return (
        <LinkActionItem
          label={label}
          route={`${baseRoute ? baseRoute : ""}${route}`}
          component={component}
        />
      );
    }
    return null;
  };

  switch (type) {
    case "grid":
      if ("gridUrl" in actionObject) {
        const { label, gridUrl } = actionObject;
        return (
          <LinkActionItem label={label} route={gridUrl} component={component} />
        );
      }
      return null;

    case "cancel":
      if ("api" in actionObject) {
        const { label, text, api } = actionObject as CancelAction;
        return <CancelActionItem label={label} text={text} api={api} />;
      }
      return null;
    case "link":
      return getLinkActionItem();
    case "list":
      return getLinkActionItem();
    case "view":
      return getLinkActionItem(baseForm);
    case "new":
      return getLinkActionItem(baseForm);
    case "edit":
      return getLinkActionItem(baseForm);
    case "editEntity":
      return getLinkActionItem(baseForm);
    case "export":
      const {
        label,
        api,
        fileType,
        fileName,
      } = actionObject as ExportActionProps;
      return (
        <ExportActionItem
          api={api}
          fileType={fileType}
          label={label}
          fileName={fileName}
          handleClose={handleClose}
        />
      );
    case "downLoadPDF":
      if ("href" in actionObject) {
        const { label, href } = actionObject;
        return <DownloadPDFItem label={label} href={href} />;
      }
      return null;
    default:
      return null;
  }
};

export default ActionItem;
