import React from "react";
import CancelButton, { CancelButtonProps } from "./CancelButton";
import EntityCancelButton, {
  EntityCancelButtonProps,
} from "./EntityCancelButton";
import CreateCaseButton, { CreateCaseButtonProps } from "./CreateCaseButton";
import SubmitButton, { SubmitButtonProps } from "./Form/SubmitButton";

export const actionButtonMap = {
  cancel: ({ entityType, formChanged, onCancel }: CancelButtonProps) => (
    <CancelButton
      entityType={entityType}
      formChanged={formChanged}
      onCancel={onCancel}
    />
  ),

  submit: ({ submitting }: SubmitButtonProps) => (
    <SubmitButton submitting={submitting} />
  ),

  cancelEntity: ({
    title,
    entityType,
    id,
    buttonLabel,
  }: EntityCancelButtonProps) => (
    <EntityCancelButton
      title={title}
      entityType={entityType}
      id={id}
      buttonLabel={buttonLabel}
    />
  ),

  createCase: ({ title, serviceId }: CreateCaseButtonProps) => (
    <CreateCaseButton title={title} serviceId={serviceId} />
  ),
};
