import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import MDialog from "./MDialog";

export type CancelButtonProps = {
  entityType: string;
  onCancel: Function;
  formChanged: boolean;
};

const CancelButton = ({
  entityType,
  onCancel,
  formChanged,
}: CancelButtonProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    if (!formChanged) {
      history.push(`/list/${entityType}`);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const _onCancel = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <Fragment>
      <Button
        variant={"contained"}
        color={"secondary"}
        fullWidth
        onClick={handleClick}
      >
        Return to list
      </Button>
      <MDialog
        dialogContent={<p>Are you sure want to return to list?</p>}
        open={showModal}
        handleClose={handleClose}
        onConfirm={_onCancel}
        continueButtonLabel={"YES"}
        closeButtonLabel={"NO"}
      />
    </Fragment>
  );
};

export default CancelButton;
