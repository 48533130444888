import { AlertProps } from "@material-ui/lab";
import React from "react";
import Message from "./Message";

export type MessageListProps = {
  messageList: string[];
  type: AlertProps["severity"];
};

const MessageList = ({ messageList, type }: MessageListProps) => {
  return (
    <div>
      {messageList.map((message, index) => (
        <Message type={type} message={message} key={index} />
      ))}
    </div>
  );
};

export default MessageList;
