import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import AsyncAutoComplete from "../AsyncAutoComplete";
import {API_URL} from "../../service/api/apiUrl";
import {useHistory} from "react-router";


const useStyles = makeStyles((theme) => ({
    labelStyle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black'
    },
    titleStyle: {
        fontSize: 13
    },
    subtitleStyle: {
        color: theme.palette.text.secondary,
        fontSize: 13
    },

}));

type OptionProps = {
    id: string,
    entityType: string,
    title: string,
    subtitle: string,
    recordLabel: string
}


const GlobalSearch = () => {
    const {labelStyle, titleStyle, subtitleStyle} = useStyles();
    const history = useHistory();
    //to make input to rerender so reset value
    const [key, setKey] = useState(0)

    const renderOption = (option: OptionProps) => {
        const {recordLabel, id, entityType, title, subtitle} = option
        const clickOption = () => {
            history.push(`/form/view/${entityType}/${id}`)
            setKey(key + 1)

        }

        return (
            <div onClick={clickOption}>
                <p className={labelStyle}>{recordLabel}</p>
                <p className={titleStyle}> {title}</p>
                <p className={subtitleStyle}> {subtitle}</p>
            </div>
        );
    }

    return (
        <AsyncAutoComplete
            renderOption={renderOption}
            apiUrl={`${API_URL.SEARCH}/global`}
            renderInputProps={{variant: 'outlined', size: 'small', placeholder: 'Search'}}
            key={key}
        />
    )
}


export default GlobalSearch

