import React, { useState } from "react";
import useRequest from "../../Hooks/useRequest";
import { fetchData } from "../../service/common";
import { saveAs } from "file-saver";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

export type ExportActionProps = {
  label: string;
  api: string;
  fileType: string;
  fileName: string;
  handleClose?: () => void;
};

const ExportActionItem = ({
  label,
  api,
  fileType,
  fileName,
  handleClose,
}: ExportActionProps) => {
  const blobTypeMap = {
    json: "application/json",
    csv: "text/csv;charset=UTF-8",
  };
  const [label_, setLabel_] = useState(label);

  const { loading, runRequest } = useRequest();
  const exportData = () => {
    runRequest(fetchData(`${api}&format=${fileType}`))
      .then((data) => {
        const blobData =
          fileType === "json" ? JSON.stringify(data.data) : data.data;
        const file = new File([blobData], `${fileName}.${fileType}`, {
          type: blobTypeMap[fileType],
        });
        saveAs(file);
        setLabel_("export complete");
        setTimeout(() => {
          setLabel_(label);
        }, 1500);
        setTimeout(() => {
          handleClose && handleClose();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MenuItem onClick={exportData}>
      <ListItemText>{loading ? "export in progress" : label_} </ListItemText>
    </MenuItem>
  );
};

export default ExportActionItem;

const testdata =
  "Service ID,Name,Status,Account,POI,Location ID,Service Address,Product,Speed,Order #,References\n19000011381,Fibre NBN TC4-25/5Mbps - 1.5 mbps included CVC,Pending,Flip TV,,LOC000186988125,UNIT 21.02 117 BATHURST ST SYDNEY NSW 2000,,255,,\n19000009639,Fibre NBN TC4-12/1Mbps - 0.15 mbps included CVC,Active,Flip TV,,LOC000038128480,208 BATHURST ST WEST HOBART TAS 7000,,121,,NBN:AVC000135144603\n19000010847,Fibre NBN TC4-50/20Mbps - 2.5 mbps included CVC,Active,Flip TV,,LOC000186989319,UNIT 3503 115 BATHURST ST SYDNEY NSW 2000,,5020,,NBN:AVC000136096521\n19000011382,Fibre NBN TC4-25/5Mbps - 1.5 mbps included CVC,Pending,Flip TV,,LOC000186990206,UNIT 55.08 117 BATHURST ST SYDNEY NSW 2000,,255,,\n19000011383,Fibre NBN TC4-25/5Mbps - 1.5 mbps included CVC,Pending,Flip TV,,LOC000186990898,UNIT 65.05 117 BATHURST ST SYDNEY NSW 2000,,255,,\n19000010407,Fibre To The Node NBN TC4-50/20Mbps - 2.5 mbps included CVC,Active,Flip TV,,LOC000099972305,LOT D 46 COMMONWEALTH ST WEST BATHURST NSW 2795,,5020,,NBN:AVC000135881543\n19000010452,Fibre To The Node NBN TC4-12/1Mbps - 0.15 mbps included CVC,Active,Flip TV,,LOC000187035706,LOT 31 242 PIPER ST BATHURST NSW 2795,,121,,NBN:AVC000135884579\n19000010334,Fibre To The Node NBN TC4-12/1Mbps - 0.15 mbps included CVC,Active,Flip TV,,LOC000048845563,9 MANILLA ST BATHURST NSW 2795,,121,,NBN:AVC000135881429\n19000011380,Fibre NBN TC4-25/5Mbps - 1.5 mbps included CVC,Pending,Flip TV,,LOC000186987836,UNIT 18.07 117 BATHURST ST SYDNEY NSW 2000,,255,,\n19000011332,Fibre NBN TC4-12/1Mbps - 0.15 mbps included CVC,Active,Flip TV,,LOC000135867924,UNIT 3 53 55 BATHURST ST LIVERPOOL NSW 2170,,121,,NBN:AVC000136724973\n";
