import React, { ReactElement } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";

export type ActionButtonProps = {
  type: string;
  display: boolean;
  params?: {
    buttonLabel: string;
    title: string;
  };
};

export type GetActionButtonProps = Omit<ActionButtonProps, "display">;

type ActionButtonsGroupProps = {
  buttons: ActionButtonProps[];
  getActionButton: (props: GetActionButtonProps) => ReactElement | null;
};
type ActionButtonsGroupBaseProps = ActionButtonsGroupProps & {
  outWrapperStyle?: string;
  buttonWrapperStyle?: string;
};

const useBaseButtonStyles = makeStyles((theme) => ({
  // @ts-ignore
  outWrapperStyle: ({ buttonsLength }) => ({
    display: "flex",
    justifyContent: buttonsLength === 2 ? "space-between" : "flex-end",
  }),
  // @ts-ignore
  buttonWrapperStyle: ({ buttonsLength }) => ({
    width: buttonsLength === 1 ? "100%" : buttonsLength === 2 ? "48%" : "auto",
    marginLeft: 0,
  }),
}));

const useActionButtonsBaseStyles = makeStyles((theme) => ({
  defaultOutWrapperStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  defaultButtonWrapperStyle: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 8,
  },
}));

const ActionButtonsGroupBase = ({
  buttons,
  getActionButton,
  outWrapperStyle,
  buttonWrapperStyle,
}: ActionButtonsGroupBaseProps) => {
  const {
    defaultOutWrapperStyle,
    defaultButtonWrapperStyle,
  } = useActionButtonsBaseStyles();
  return (
    <Box
      className={
        outWrapperStyle ? `${outWrapperStyle}` : defaultOutWrapperStyle
      }
    >
      {buttons.map(({ type, params }: ActionButtonProps, index: number) => (
        <Box
          className={
            buttonWrapperStyle
              ? `${buttonWrapperStyle}`
              : defaultButtonWrapperStyle
          }
          key={index}
        >
          {getActionButton({ type, params })}
        </Box>
      ))}
    </Box>
  );
};

export const TopButtons = ({
  buttons,
  getActionButton,
}: ActionButtonsGroupProps) => (
  <ActionButtonsGroupBase getActionButton={getActionButton} buttons={buttons} />
);

export const BaseButtons = ({
  buttons,
  getActionButton,
}: ActionButtonsGroupProps) => {
  const { buttonWrapperStyle, outWrapperStyle } = useBaseButtonStyles({
    buttonsLength: buttons.length,
  });

  return (
    <ActionButtonsGroupBase
      getActionButton={getActionButton}
      buttons={buttons}
      buttonWrapperStyle={buttonWrapperStyle}
      outWrapperStyle={outWrapperStyle}
    />
  );
};
