import React, { Fragment, MouseEventHandler, ReactNode } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SubmitButton from "./Form/SubmitButton";

type MDialog = DialogProps & {
  onConfirm: MouseEventHandler;
  handleClose: MouseEventHandler;
  loading?: boolean;
  disabled?: boolean;
  dialogContent: ReactNode;
  continueButtonLabel?: string;
  closeButtonLabel?: string;
};

const MDialog = (props: MDialog) => {
  const {
    onConfirm,
    maxWidth,
    dialogContent,
    open,
    handleClose,
    loading,
    disabled = false,
    continueButtonLabel = "Continue",
    closeButtonLabel = "close",
    ...rest
  } = props;

  return (
    <Fragment>
      <Dialog
        maxWidth={maxWidth ? maxWidth : "md"}
        fullWidth
        open={open}
        onClose={handleClose}
        {...rest}
      >
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <div onClick={onConfirm}>
            <SubmitButton
              submitting={loading}
              disabled={disabled}
              buttonProps={{ variant: "text" }}
            >
              {continueButtonLabel}
            </SubmitButton>
          </div>
          <Button onClick={handleClose} color="primary">
            {closeButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default MDialog;
