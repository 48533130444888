import React, { FormEvent } from "react";
import { Box, Container, TextField } from "@material-ui/core";
import FormTitle from "./FormTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  forgotPasswordSubmit,
} from "../../features/auth/authSlice";
import useFormFields from "../../Hooks/useFormFields";
import SubmitButton from "../../components/Form/SubmitButton";

const ForgotPasswordSubmit: React.FC = () => {
  const { loading, error, email } = useSelector(authSelector);
  const { formFields, handleChangeValue } = useFormFields({
    _email: email,
    password: "",
    confirmationCode: "",
  });

  const dispatch = useDispatch();

  const { password, confirmationCode, _email } = formFields;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(forgotPasswordSubmit(_email, confirmationCode, password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" mt={8}>
          <FormTitle>Please confirm your email</FormTitle>
          <Box
            display="flex"
            justifyContent="center"
            fontWeight={600}
            color="error.main"
          >
            {error}
          </Box>
          <Box width="100%" my={2}>
            <TextField
              label="Email"
              onChange={handleChangeValue("email")}
              value={_email}
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "email" }}
            />
          </Box>

          <Box width="100%" my={2}>
            <TextField
              label="Confirmation Code"
              onChange={handleChangeValue("confirmationCode")}
              value={confirmationCode}
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "confirmationCode" }}
            />
          </Box>

          <Box width="100%" my={2}>
            <TextField
              label="New Password"
              type="password"
              autoComplete="new-password"
              onChange={handleChangeValue("password")}
              value={password}
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "password" }}
            />
          </Box>

          <Box width="100%" mt={4} mb={2} className="relative">
            <SubmitButton submitting={loading}>Confirm</SubmitButton>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default ForgotPasswordSubmit;
