import React, { FormEvent } from "react";
import { Box, Container, Link, TextField } from "@material-ui/core";
import FormTitle from "./FormTitle";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, forgotPassword } from "../../features/auth/authSlice";
import useFormFields from "../../Hooks/useFormFields";
import SubmitButton from "../../components/Form/SubmitButton";

const ForgotPassword: React.FC = () => {
  const { formFields, handleChangeValue } = useFormFields({ email: "" });
  const dispatch = useDispatch();
  const { loading, error } = useSelector(authSelector);
  const { email } = formFields;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(forgotPassword(email));
  };

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" mt={8}>
          <FormTitle>Reset Your Password</FormTitle>
          <Box
            display="flex"
            justifyContent="center"
            fontWeight={600}
            color="error.main"
          >
            {error}
          </Box>
          <Box width="100%" my={2}>
            <TextField
              autoComplete="email"
              type="email"
              onChange={handleChangeValue("email")}
              value={email}
              label="Email Address"
              variant="outlined"
              required
              fullWidth
              inputProps={{ "data-testid": "email" }}
            />
          </Box>

          <Box width="100%" mt={4} mb={2} className="relative">
            <SubmitButton data-testid="submitButton" submitting={loading}>
              Send Code
            </SubmitButton>
          </Box>
          <Box width="100%" my={2}>
            <Link data-testid="signInLink" href="/login" variant="body2">
              Back to Sign in
            </Link>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default ForgotPassword;
