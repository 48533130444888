import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    },
    html: {
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      height: "100%",
      width: "100%",
    },
    body: {
      // backgroundColor: '#f4f6f8',
      height: "100%",
      width: "100%",
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    "a:not(.MuiButton-root)": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "#root": {
      height: "100%",
      width: "100%",
    },
    h1: {
      textAlign: "center",
    },
    ".flex-center-center": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
