import {createSlice} from '@reduxjs/toolkit'
import {AppState} from '../../common/types'


const initialState = {
    param: null,
    shallReloadForm: false

};


// A slice for recipes with our three reducers
const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {

        changeParam: (state, {payload}) => {
            state.param = payload

        },
        reloadForm: (state, {payload}) => {
            state.shallReloadForm= payload
            console.log(payload)
        },

    }
})


export const {changeParam, reloadForm} = formSlice.actions
export const formSelector = (state: AppState) => state.form
export default formSlice.reducer

//=============================================================================
//Actions
//=============================================================================


//=============================================================================
//Async Operations
//=============================================================================


