import React from "react";
import { WidgetProps } from "@rjsf/core";
import { makeStyles } from "@material-ui/core/styles";
import AsyncAutoComplete from "../AsyncAutoComplete";
import { Modify } from "../../common/types";

type NewSchema = WidgetProps["schema"] & { config: { url: string } };
type AsyncAutoCompleteWidgetProps = Modify<WidgetProps, { schema: NewSchema }>;
type OptionProps = {
  title: string;
  subtitle: string;
  id: string;
  recordLabel?: string;
};

const useStyles = makeStyles((theme) => ({
  labelStyle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
  },
  subtitleStyle: {
    color: theme.palette.text.secondary,
    fontSize: 13,
  },
  titleStyle: {
    fontSize: 13,
  },
}));

const AsyncAutoCompleteWidget = (props: AsyncAutoCompleteWidgetProps) => {
  const { labelStyle, subtitleStyle, titleStyle } = useStyles();
  const { label, schema, onChange } = props;

  const apiUrl = `${schema.config.url}`;
  const _onChange = (newValue: { id: any }) => {
    if (newValue) {
      onChange(newValue.id);
    } else {
      onChange(undefined);
    }
  };

  const renderOption = (option: OptionProps) => {
    const { title, subtitle, recordLabel } = option;

    return (
      <div>
        <p className={labelStyle}>{recordLabel}</p>
        <p className={titleStyle}>{title}</p>
        <p className={subtitleStyle}> {subtitle}</p>
      </div>
    );
  };

  return (
    <AsyncAutoComplete
      apiUrl={apiUrl}
      renderOption={renderOption}
      label={label}
      onChange={_onChange}
    />
  );
};

export default AsyncAutoCompleteWidget;
