import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ObjectFieldTemplateProps } from "@rjsf/core";
import { Box } from "@material-ui/core";
import { stringIndexObject } from "../../common/types";

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    border: "solid 2px lightBlue",
    borderRadius: "15px",
    padding: "20px 10px 20px 10px",
  },
  key: {
    fontSize: 16,
    marginRight: 8,
    textTransform: "capitalize",
    fontWeight: 600,
    display: "inline-block",
    float: "left",
    width: "30%",
    textAlign: "right",
  },
  value: {
    fontSize: 16,
    display: "inline-block",
    width: "60%",
  },
  subtitle: {
    color: "blue",
    marginTop: 16,
    fontSize: 16,
  },
});

type dlGroupsArray = Array<{
  title: string | null;
  properties: stringIndexObject;
}>;
type dlObject = { title: string | null; properties: object };
type property = {
  content: {
    props: {
      formData: string | object;
      schema: { type: string; title: string; properties: object };
    };
  };
};

const flattenRoData = (properties: Array<property>) => {
  const dlGroupArray: Array<dlObject> = [];
  const subObject: dlObject | {} = {};

  properties.forEach((property: property) => {
    const { formData, schema } = property.content.props;
    const { type, title, properties } = schema;

    if (type === "string") {
      subObject[title] = formData;
    } else if (type === "object") {
      const covertedObject = {};

      Object.keys(properties).forEach((key: string) => {
        const title = properties[key]["title"];
        covertedObject[title] = formData[key];
      });

      dlGroupArray.push({
        title,
        properties: covertedObject,
      });
    }
  });
  dlGroupArray.unshift({ title: null, properties: subObject });
  return dlGroupArray;
};

const DlGroups = ({ dlGroupsArray }: { dlGroupsArray: dlGroupsArray }) => {
  const { key, value, subtitle } = useStyles();

  return (
    <Box display="flex" flexDirection="column" width="100%" py={2}>
      {dlGroupsArray.map((group, index) => {
        const { title, properties } = group;

        return (
          <dl key={index}>
            {title ? (
              <Fragment>
                <dt className={key}>
                  <h5 className={subtitle}></h5>
                </dt>
                <dd className={value}>
                  <h5 className={subtitle}>{title}</h5>
                </dd>
              </Fragment>
            ) : null}
            {Object.keys(properties).map((ele, index) => (
              <Fragment key={`${ele}${index}`}>
                <dt className={key}>{ele}</dt>
                <dd className={value}>{properties[ele]}</dd>
              </Fragment>
            ))}
          </dl>
        );
      })}
    </Box>
  );
};

const RoObjectTemplate = ({
  DescriptionField,
  description,
  properties,
  idSchema,
}: ObjectFieldTemplateProps) => {
  const classes = useStyles();

  return (
    <div>
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}

      <Grid container={true} className={classes.root}>
        <DlGroups dlGroupsArray={flattenRoData(properties)} />
      </Grid>
    </div>
  );
};

export default RoObjectTemplate;
