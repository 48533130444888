import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import SquareChip from "../SquareChip";

const statusMap = {
  NEW: <SquareChip label="New" color="primary" variant={"outlined"} />,
  "IN PROGRESS": (
    <SquareChip color={"default"} label="In progress" variant={"outlined"} />
  ),

  COMPLETE: (
    <SquareChip label="Complete" color="secondary" variant={"outlined"} />
  ),
};

type Key = "NEW" | "IN PROGRESS" | "COMPLETE";

const StatusFormatter = ({ value }: { value: Key }) => {
  const content = statusMap[value];
  if (content) {
    return statusMap[value];
  } else return null;
};

const StatusTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);

export default StatusTypeProvider;
