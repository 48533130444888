import { combineReducers } from "redux";
import Amplify from "aws-amplify";
import cognito_exports from "../cognito-exports";
import authReducer from "./auth/authSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import formReducer from "./form/formSlice";

Amplify.configure(cognito_exports);

export default function createReducer(asyncReducers: any) {
  return combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    form: formReducer,
    ...asyncReducers,
  });
}
