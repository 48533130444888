import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { postData } from "../service/common";
import debounce from "../common/util/debouce";

type option = {
  title: string;
  subtitle: string;
  id: string;
  entityType?: string;
};
type options = Array<option>;

interface AsyncAutoCompleteProps {
  renderOption: AutocompleteProps<any, any, any, any>["renderOption"];
  label?: string;
  apiUrl: string;
  renderInputProps?: object;
  onChange?: Function;
}

const AsyncAutoComplete = ({
  renderOption,
  label,
  apiUrl,
  renderInputProps,
  onChange,
}: AsyncAutoCompleteProps) => {
  const [value, setValue] = useState<option | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<options>([]);
  const [loading, setLoading] = useState(false);

  const _debounce = useRef(debounce());

  const _renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      {...renderInputProps}
      label={label || ""}
      fullWidth
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );

  const apiCall = () => {
    setLoading(true);
    postData(`${apiUrl}`, { query: inputValue })
      .then((data) => {
        console.log(data);
        setOptions(data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (inputValue && value === null) {
      _debounce.current(apiCall, 500);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.title}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      onChange={(event, newValue: option | null) => {
        setValue(newValue);
        onChange && onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={_renderInput}
      renderOption={renderOption}
    />
  );
};

export default AsyncAutoComplete;
