import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import GridList from "./GridList";
import { useParams } from "react-router";
import { getSlice } from "../../features/helper";
import { AppState } from "../../common/types";
import React, { useState } from "react";
import LoadingComponent from "../LoadingComponent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilterSection from "./FilterSection/FilterSection";
import FilterIcon from "./FilterSection/filterIcon";
import { checkCreateNewPermission } from "./Helper";
import { dashboardSelector } from "../../features/dashboard/dashboardSlice";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ExportList from "./ExportList";
import BulkActionSelect from "./BulkActionSelect";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginRight: 16,
  },
}));

const GridListContainer = () => {
  const classes = useStyles();
  const { name } = useParams<{ name: string }>();
  const sliceName = `${name}list`;
  const listSlice = getSlice(sliceName);
  const listSelector = (state: AppState) => state[sliceName];
  const listParams = useSelector(listSelector);
  const { menu } = useSelector(dashboardSelector);
  const listActions = listSlice.actions;
  const api = `/grid/${name}`;
  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => setShowFilter(!showFilter);

  if (!listParams) {
    return (
      <Box textAlign="center">
        <LoadingComponent />
      </Box>
    );
  }

  const { rows, bulkAction, filterConfig } = listParams;
  const { updateFilters } = listActions;

  return (
    <div>
      <Box width="100%" my={3} display="flex" justifyContent="space-between">
        <h2 className={classes.title}>
          {name === "eequote" ? "quote" : name}S
        </h2>
        <Box display="flex" alignItems="center">
          {rows.length > 0 && (
            <ExportList api={api} listParams={listParams} fileName={name} />
          )}
          <FilterIcon onClick={toggleFilter} />
          {menu && checkCreateNewPermission(Object.values(menu), name) && (
            <Button
              component={Link}
              to={`/form/new/${name}`}
              variant={"contained"}
              color={"primary"}
            >
              Add New
            </Button>
          )}
        </Box>
      </Box>

      {showFilter && (
        <Box mb={2}>
          <FilterSection
            filterConfig={filterConfig}
            updateFilters={updateFilters}
          />
        </Box>
      )}
      {bulkAction && (
        <Box mb={2}>
          <BulkActionSelect data={bulkAction} listState={listParams} />
        </Box>
      )}
      <GridList listState={listParams} listActions={listActions} api={api} />
    </div>
  );
};

export default GridListContainer;

const data_ = {
  delete: {
    label: "Delete",
    selectAll: true,
    url: "actionRoute/delete/eequote",
    confirmMessage:
      "The selected Quotes will be deleted, click confirm to continue",
  },
};
