import React, { Fragment, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import useFormFields from "../Hooks/useFormFields";
import { postData } from "../service/common";
import Button from "@material-ui/core/Button";
import { reloadForm } from "../features/form/formSlice";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import SubmitButton from "./Form/SubmitButton";
import useRequest from "../Hooks/useRequest";
import MessageList, { MessageListProps } from "./MessageList";
import handleErrorMessage from "../common/util/handleErrorMessage";

export type CreateCaseButtonProps = {
  title: string;
  serviceId: string;
};

const useStyles = makeStyles((theme) => ({
  titleSection: {
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "lightgray",
  },
}));

const CreateCaseButton = ({ title, serviceId }: CreateCaseButtonProps) => {
  const { titleSection } = useStyles();
  const dispatch = useDispatch();
  const { formFields, handleChangeValue, setFormFields } = useFormFields({
    subject: "",
    description: "",
  });
  const { subject, description } = formFields;
  const [messageList, setMessageList] = useState<MessageListProps>({
    messageList: [],
    type: undefined,
  });
  const { messageList: messageList_, type } = messageList;
  const [open, setOpen] = useState(false);
  const { loading, runRequest } = useRequest();

  const clickButton = async () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    clearState();
  };

  const clearState = () => {
    setFormFields({ confirmInput: "" });
    setMessageList({
      messageList: [],
      type: undefined,
    });
  };
  // @ts-ignore
  const onSubmit = (e) => {
    e.preventDefault();
    const url = `/actionroute/case/submit/`;
    const data = {
      Description: description,
      ServiceId: serviceId,
      Subject: subject,
    };

    runRequest(postData(url, data))
      .then((data) => {
        setMessageList({
          messageList: [data.data.message || "Success"],
          type: "success",
        });
      })
      .catch((err) => {
        const errMessage = handleErrorMessage(err);
        setMessageList({
          messageList: errMessage,
          type: "error",
        });
      });
  };

  const clickClose = () => {
    closeModal();
    if (type === "success") {
      dispatch(reloadForm(true));
    }
  };

  return (
    <Fragment>
      <Button variant={"contained"} color={"default"} onClick={clickButton}>
        Create case
      </Button>

      <Dialog fullWidth open={open} aria-labelledby="dialog-title">
        <Box className={titleSection}>
          <h3>{title}</h3>
          <Button onClick={clickClose} color="primary">
            <CloseIcon />
          </Button>
        </Box>
        <Box mb={2}>
          <DialogContent>
            {messageList_.length > 0 ? (
              <MessageList messageList={messageList_} type={type} />
            ) : (
              <form onSubmit={onSubmit}>
                <Box mt={2}>
                  <TextField
                    label={"subject"}
                    onChange={handleChangeValue("subject")}
                    value={subject}
                    variant="outlined"
                    required
                    size={"small"}
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    label={"description"}
                    onChange={handleChangeValue("description")}
                    value={description}
                    variant="outlined"
                    required
                    size={"small"}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>
                <Box mt={2}>
                  <SubmitButton submitting={loading} />
                </Box>
              </form>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
};

export default CreateCaseButton;
