import React, { Fragment } from "react";

type AuthMenuItemProps = {
  authState: string;
  children: React.ReactNode;
};

const AuthMenuItem = ({ authState, children }: AuthMenuItemProps) => {
  return authState === "authed" ? <Fragment>{children}</Fragment> : null;
};

export default AuthMenuItem;
