import { useState } from "react";
import { HandleErrorProps } from "../common/util/handleErrorMessage";

const useRequest = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<HandleErrorProps | null>(null);

  const runRequest = async (promise: Promise<any>) => {
    setLoading(true);
    try {
      const data = await promise;
      setLoading(false);
      setData(data);
      return data;
    } catch (error) {
      setLoading(false);
      setError(error);
      return Promise.reject(error);
    }
  };

  return { data, loading, error, runRequest };
};

export default useRequest;
