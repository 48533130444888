import React from "react";
import ProfileDetails from "./ProfileDetail";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../features/dashboard/dashboardSlice";
import LinkActionItem from "../../components/ActionComponent/LinkActionItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    height: "100%",
    width: "100%",
    padding: 16,
  },
  wrapper: {
    display: "flex",
    flexGrow: 1,
  },
  left: {
    display: "flex",
    flexGrow: 1,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const menu = {
  profile: {
    label: "example",
    action_type: "view",
    action_entity: "profile",
  },

  organization: {
    label: "organization",
    action_type: "view",
    action_entity: "organization",
  },
  Orders: {
    label: "Orders",
    children: [
      {
        label: "ORDERS",
        action_type: "list",
        action_entity: "order",
      },
      {
        label: "NEW ORDER",
        action_type: "new",
        action_entity: "order",
      },
    ],
  },
};

const Org = () => {
  const { organisation } = useSelector(dashboardSelector);
  return <h3>{organisation}</h3>;
};

const ViewMap: { [index: string]: any } = {
  Profile: <ProfileDetails />,
};

const UserSection = () => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const topicIds = Object.keys(ViewMap);
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3} className={classes.wrapper}>
        <Grid item md={3} xs={12} className={classes.left}>
          <Card className={classes.card}>
            <ul>
              {topicIds.map((id) => (
                <Box borderBottom={1}>
                  <LinkActionItem label={id} route={`${url}/${id}`} />
                </Box>
              ))}{" "}
            </ul>
          </Card>
        </Grid>
        <Grid item md={9} xs={12} className={classes.right}>
          <Card className={classes.card}>
            <Switch>
              <Route exact path={path}>
                <ProfileDetails />
              </Route>
              <Route exact path={`/account/:view`}>
                <AccountView />
              </Route>
            </Switch>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const AccountView = () => {
  let { view } = useParams();

  return <div>{ViewMap[view]}</div>;
};

export default UserSection;
