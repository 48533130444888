import React, { ReactNode } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ActionItem, { ActionItemType, actionObjectType } from "./ActionItem";

export type Option = { type: ActionItemType; actionObject: actionObjectType };
type OptionWithChildren = {
  type: ActionItemType;
  label: string;
  children: Option[];
};
type ActionDropDownProps = {
  options: Array<Option | OptionWithChildren>;
  name?: string | ReactNode;
  actionContainer?: ReactNode;
};

//https://stackoverflow.com/questions/54017454/jsx-element-type-element-is-not-a-constructor-function-for-jsx-elements

const ActionDropDown = ({
  options,
  name,
  actionContainer,
}: ActionDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {actionContainer ? (
        <div onClick={handleClick}>{actionContainer}</div>
      ) : (
        <MenuItem onClick={handleClick}>
          <ListItemText>{name ? name : "menu"}</ListItemText>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </MenuItem>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map((option, index: number) =>
          "children" in option ? (
            <ActionDropDown
              options={option.children}
              name={option.label}
              key={index}
            />
          ) : (
            <ActionItem
              type={option.type}
              actionObject={option.actionObject}
              handleClose={handleClose}
              key={index}
            />
          )
        )}
      </Menu>
    </div>
  );
};

export default ActionDropDown;
