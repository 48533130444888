import withStyles from "@material-ui/core/styles/withStyles";
import {FormLabel} from "@material-ui/core";

const MFormLabel = withStyles((theme) => ({
    root: {
        transform: 'scale(0.85)',
        transformOrigin: 'top left'

    },
}))(FormLabel);

export default MFormLabel