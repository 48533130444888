import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import theme from "../theme";


const useStyles = makeStyles({
    valueStyle: {
        color: theme.palette.text.secondary
    },
});


type NodeType = object | string | number | boolean | null | NodeType[]

type LabelComponentProps = {
    label: string,
    value: string | number | boolean
}

const LabelComponent = ({label, value}: LabelComponentProps) => {
    const {valueStyle} = useStyles()
    return (
        <div>
            <span>{label} : </span>
            <span className={valueStyle}>{value}</span>
        </div>
    )
}


const MTreeView = ({values}: { values: { [index: string]: string | object } }) => {

    const topNodeLabel = Object.keys(values)[0]
    const topNode = values[topNodeLabel]
    const id = useRef(0)
    const isStringOrBooleanOrNumber = (node: NodeType) => typeof node === "string" || typeof node === "number" || typeof node === "boolean"

    const renderTree = (node: NodeType, label: string) => {

        id.current += 1;
        const _id = id.current.toString()


        if (isStringOrBooleanOrNumber(node)) {


            return (
                <TreeItem
                    key={_id}
                    nodeId={_id}
                    label={<LabelComponent label={label} value={node as LabelComponentProps['value']}/>}
                >
                </TreeItem>
            )
        }


        if (typeof (node) === "object" && !Array.isArray(node) && node !== null) {

            const keys = Object.keys(node)

            return (
                <TreeItem key={_id} nodeId={_id} label={label}>
                    {
                        keys.map((key) => {
                            const value = node[key]
                            return renderTree(value, key)
                        })
                    }
                </TreeItem>
            )
        }


        if (Array.isArray(node)) {
            return <TreeItem key={_id} nodeId={_id} label={label}>
                {node.map((subNode, index) => renderTree(subNode, index.toString()))}
            </TreeItem>
        }


        if (node === null) {


            return (
                <TreeItem
                    key={_id}
                    nodeId={_id}
                    label={<LabelComponent label={label} value={'null'}/>}
                >
                </TreeItem>
            )
        }

    }


    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
        >
            {renderTree(topNode, topNodeLabel)}
        </TreeView>
    );
}

export default MTreeView

