export const API_URL = {

    PORTAL: {},

    FILE: {

        UPLOAD: 'file/temp/create',
        DELETE: '/file/temp'
    },
    MENU: 'user',
    SEARCH:'search'


};


