import { createSlice } from "@reduxjs/toolkit";
import { AppState, DashboardState } from "../../common/types";

const initialState: DashboardState = {
  loading: true,
  lastGenerated: "",
};

const resetState = (state: DashboardState) => {
  state.loading = false;
};

// A slice for recipes with our three reducers
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardLoading: (state) => {
      state.loading = true;
    },

    loadingError: (state, { payload }) => {
      state.loading = false;
      console.log("Error Loading ", payload);
    },

    fetchedUser: (state: DashboardState, action) => {
      state.name = action.payload.name;
      state.organisation = action.payload.organisation;
    },

    fetchDashboardSuccess: (state: DashboardState, action) => {
      const {
        menu,
        dashboard: {
          quoteGauge,
          orderGauge,
          notifications,
          quoteTopStates,
          orderTopStates,
          openCaseCount,
        },
      } = action.payload;

      state.menu = menu;
      state.quoteGauge = quoteGauge;
      state.orderGauge = orderGauge;
      state.notifications = notifications;
      state.lastGenerated = new Date().toLocaleDateString();
      state.quoteTopStates = quoteTopStates;
      state.orderTopStates = orderTopStates;
      state.openCaseCount = openCaseCount;
      state.loading = false;
    },
  },
});

export const {
  dashboardLoading,
  loadingError,
  fetchDashboardSuccess,
  fetchedUser,
} = dashboardSlice.actions;

export const dashboardSelector = (state: AppState) => state.dashboard;
export const dashboardSelectorQuoteGauge = (state: AppState) =>
  state.dashboard.quoteGauge;
export default dashboardSlice.reducer;

//=============================================================================
//Actions
//=============================================================================

//=============================================================================
//Async Operations
//=============================================================================
