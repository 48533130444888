import React, { useEffect, useState } from "react";
import { deleteFile, uploadFile } from "../../service/common";
import Box from "@material-ui/core/Box";
import DropZone from "./DropZone";
import { FileObject } from "material-ui-dropzone";
import { WidgetProps } from "@rjsf/core";

type WantedProperty = { id?: string };
type EnhancedFileObject = FileObject & WantedProperty;

export const MultipleFileUpload = (props: WidgetProps) => {
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [LoadedFiles, setLoadedFiles] = useState<EnhancedFileObject[]>([]);
  //const fieldName=props.id.split('_').slice(1).join('_')

  const onAdd = async (files: EnhancedFileObject[]) => {
    const _files = [...files];
    _files.map((file) =>
      uploadFile(file).then((data) => {
        const id = data.data.id;
        setFileIds((filedIds) => [...filedIds, id]);

        file.id = id;
        setLoadedFiles((LoadedFiles) => [...LoadedFiles, file]);
      })
    );
  };

  const onDelete = (file: EnhancedFileObject) => {
    const id = file.id;
    if (id) {
      deleteFile(id).then((data) => {
        const newFileIds = fileIds.filter((id) => id !== file.id);
        setFileIds(newFileIds);
        setLoadedFiles(LoadedFiles.filter((file) => file.id !== id));
        //props.onChange(newFileIds)
      });
    }
  };

  useEffect(() => {
    props.onChange(fileIds);
  }, [fileIds]);

  /* const SelectedFiles = ({fileList}) => (fileList.map((file) =>
        <div><span></span><span>{file.file.name}</span></div>))
*/

  return (
    <DropZone
      label={props.title || ""}
      fileObjects={LoadedFiles}
      onAdd={onAdd}
      onDelete={onDelete}
      showAlerts={["error"]}
      dropzoneProps={{ disabled: props.readonly }}
    />
  );
};

export const SingleFileUpload = (props: WidgetProps) => {
  const [fileId, setFileId] = useState("");
  const [LoadedFiles, setLoadedFiles] = useState<EnhancedFileObject[]>([]);

  const onAdd = async (files: EnhancedFileObject[]) => {
    const file = files[0];
    if (fileId) {
      await deleteFile(fileId);
    }

    uploadFile(file).then((data) => {
      const id = data.data.id;
      setFileId(id);
      file.id = id;
      setLoadedFiles(files);
    });
  };

  const onDelete = (file: EnhancedFileObject) => {
    const id = file.id;
    if (id) {
      deleteFile(id).then((data) => {
        setFileId("");
        setLoadedFiles([]);
      });
    }
  };

  useEffect(() => {
    props.onChange(fileId);
  }, [fileId]);

  return (
    <Box>
      <DropZone
        label={props.label}
        fileObjects={LoadedFiles}
        onAdd={onAdd}
        onDelete={onDelete}
        showAlerts={["error"]}
        filesLimit={1}
        dropzoneProps={{ disabled: props.readonly }}
      />
    </Box>
  );
};

const FileUpload = (props: WidgetProps) => {
  if (props.multiple) {
    return <MultipleFileUpload {...props} />;
  }

  return <SingleFileUpload {...props} />;
};

export default FileUpload;
